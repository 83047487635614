<template lang="pug">
include ../pug/svg
div.block(:class="blockClasses" @mouseleave="focus=null" :style="blockStyles")
	div.ratio(:class="block.settings.ratio")
		figure(v-for="(image, index) in images" :index="index" :image="image" :class="{active:index===currentIndex}")
			picture(:class="pictureClasses(image)")
				img(v-if="imageSrc(image)" :src="imageSrc(image)" :alt="imageAlt(image)" @load="imageChanged(image)")
			contenteditable(v-if="block.settings.figcaption&&imageSrc" tag="figcaption" :spellcheck="(focus===1)" :class="{fade:focus&&focus!==1}" v-model="image.figcaption" :noNL="true" @focus="focus=1" @blur="focus=null" @keyup="blockUpdated()" :data-placeholder="placeholder")
			
		div.butdbl.text(:class="{unselected : unselected}")
			button.but(type="button" @click="selectMedia(currentIndex)") Media
			button.but(type="button" @click="removeMedia(currentIndex)")
				span Remove Media
				+svg(svg-filename="iconBin")
			button.but(type="button" @click="embedMedia(currentIndex)") Embed
			
	ol(:class="{off:!imageSrc(images[0])}")
		li(v-for="(image, index) in images" :index="index" :image="image")
			button(:class="{active:index===currentIndex}" @click="currentIndex=index") Image {{index}}
			
	div.modal.settings(v-if="block.settings.active")
		ModalBar(label="Block Settings" :useDrag="true" @close="toggleSettings()")
		form.body
			fieldset.fgroup
				FieldBoolean.c2(
					v-model="block.settings.fullwidth"
					label="Fullwidth"
					@fieldUpdated="blockUpdated")
				FieldBoolean.c2(
					v-model="block.settings.figcaption"
					label="Use Caption"
					@fieldUpdated="figcaptionToggled")
				FieldRatio.c2(
					v-model:ratio="block.settings.ratio"
					label="Image Ratio"
					@fieldUpdated="blockUpdated")
				FieldBoolean.c2(
					v-model="block.settings.contain"
					label="Contain Media"
					@fieldUpdated="blockUpdated")
				FieldColour.c2(
					label="Background Colour"
					v-model:colour="block.settings.bgColor",
					@fieldUpdated="blockUpdated")
				FieldPadding(
					class="c2"
					v-model:padTop="block.settings.padTop"
					v-model:padBottom="block.settings.padBottom" 
					@fieldUpdated="blockUpdated")
			div.foot
				span.req Required
				button.but.pri(type="button" @click="toggleSettings()") Apply
				button.but.sec(type="button" @click="resetSettings()") Reset
</template>

<script>
import contenteditable from 'vue-contenteditable';
import FieldBoolean from '../components/FieldBoolean';
import FieldPadding from '../components/FieldPadding';
import FieldColour from '../components/FieldColour';
import FieldRatio from '../components/FieldRatio';
import ModalBar from '../components/ModalBar';

export default {
	name: 'BlockImageCarousel',
	props:['block'],
	emits: ['blockUpdated', 'toggleSettings', 'showMediaModal', 'showEmbedModal', 'removeMedia'],
	components: {
		contenteditable,
		ModalBar,
		FieldBoolean,
		FieldPadding,
		FieldColour,
		FieldRatio,
	},
	data() {
		return {
			focus: null,
			mediaType: 'image',
			placeholder: 'Caption placeholder',
			currentIndex: 0,
		}
	},
	computed: {
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];
			
			if (this.settings.fullwidth) {
				classes.push('fullwidth');
			}
			
			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}
			
			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}
			
			return classes.join(' ');
		},
		blockStyles() {
			let styles = [];

			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}

			return styles.join(';');
		},
		images() {
			return this.block.content.images;
		},
		unselected() {
			return (this.images[0].media||this.images[0].embed) ? false : true;
		},
	},
	methods: {
		blockUpdated() {
			this.$emit('blockUpdated');
		},
		figcaptionToggled() {
			if (!this.block.settings.figcaption) {
				for (let image of this.images) {
					image.figcaption = null;
				}
			} else {
				// enabled
				for (let image of this.images) {
					image.figcaption = image.media.seoDescription;
				}
			}
			
			this.blockUpdated();	
		},
		toggleSettings() {
			this.$emit('toggleSettings', this.block);
		},
		pictureClasses(image) {
			let classes = [
				'posX' + image.positionX,
				'posY' + image.positionY,
			];
			
			if (this.settings.contain) {
				classes.push('contain');
			}
			
			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}
			
			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}
			
			return classes.join(' ');
		},
		selectMedia(index) {
			// pass media type and block for insertion
			this.$emit('showMediaModal', {
				mediaType: this.mediaType,
				block: this.block,
				insertIndex: index,
			});
		},
		embedMedia(index) {
			// pass media type and block for insertion
			this.$emit('showEmbedModal', {
				mediaType: this.mediaType,
				block: this.block,
				insertIndex: index,
			});
		},
		removeMedia(index) {
			this.currentIndex = (!index) ? 0 : index - 1;
			
			this.$emit('removeMedia', {
				block: this.block,
				index: index,
			});
		},
		imageSrc(image) {
			if (image.media) {
				return image.media.mediaUrl;
			} else if (image.embed) {
				return image.embed.mediaUrl;
			}
			
			return null;
		},
		imageAlt(image) {
			if (image.media) {
				return image.media.seoAlt;
			} else if (image.embed) {
				return image.embed.seoAlt;
			}
			
			return null;
		},
		imageChanged(image) {
			if (!image.figcaption && image.media) {
				// update caption
				image.figcaption = image.media.seoDescription;	
			}
			
			this.blockUpdated();
		},
	},
}
</script>

<style lang="scss">
</style>
