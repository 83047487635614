<template lang="pug">
include ../pug/svg
div.field.c2.merge
	select(v-model="modelPadTop" @change="fieldUpdated")
		option(:value="null") Default
		option(value="pt-off") None
		option(value="pt-hlf") Half
		option(value="pt-dbl") Double
	label Padding top
	+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
	select(v-model="modelPadBottom" @change="fieldUpdated")
		option(:value="null") Default
		option(value="pb-off") None
		option(value="pb-hlf") Half
		option(value="pb-dbl") Double
	label Padding bottom
	+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
</template>

<script>
export default {
	name: 'FieldPadding',
	props: {
		class: String,
		padTop: String,
		padBottom: String,
	},
	emits: ['fieldUpdated','update:padTop','update:padBottom'],
	computed: {
		modelPadTop: {
			get() {
				return this.padTop;
			},
			set(value) {
				this.$emit('update:padTop', value);
			}
		},
		modelPadBottom: {
			get() {
				return this.padBottom;
			},
			set(value) {
				this.$emit('update:padBottom', value);
			}
		},
	},
	methods: {
		fieldUpdated() {
			this.$emit('fieldUpdated');
		},
	},
}
</script>

<style lang="scss">
</style>
