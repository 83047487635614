<template lang="pug">
include ../pug/svg
pre block.content: {{block.content}}
pre items: {{items}}
//-div.block(:class="[block.type, block.subtype]" @mouseleave="focus=null")
div.block(@mouseleave="focus=null")
	SlickList(tag="ul" lockAxis="y" v-model:list="items" useDragHandle=true helperClass="sorting" useWindowAsScrollContainer=true @update:list="blockReordered")
		SlickItem(tag="li" v-for="(item, i) in items" :index="i" :key="'li'+i" class="prow")
			contenteditable.p(:ref="setItemRef" tag="div" v-model="item.value" :noHTML="false" :noNL="true" :spellcheck="(focus===i)" :class="{fade:focus!==null&&focus!==i}" @focus="focus=i" @blur="focus=null" @keydown="onKeydown" @keyup="onKeyup" @mouseup="checkSelection")
			button.but.drag(type="button" v-handle)
				+svg(svg-filename="iconDrag" aria-hidden="true" alt="Drag icon")
				span Reorder List
			button.but.bin(type="button" @click="binItem(i)" :disabled="disableBin")
				+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
				span Remove Paragraph
			button.but.add(type="button" @click="addItem(i+1)")
				+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
				span Add Paragraph
	div.modal.settings(v-if="block.settings.active" @mouseleave="toggleSettings()")
		ModalBar(label="Block Settings" :useDrag="true" @close="toggleSettings()")
		pre {{block.settings}}
</template>

<script>
import contenteditable from 'vue-contenteditable';
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort';
import ModalBar from '../components/ModalBar';

export default {
	name: 'BlockTaskComplate',
	props:['block'],
	emits: ['updateListItems', 'toggleSettings'],
	directives: { handle: HandleDirective },
	components: {
		SlickList,
		SlickItem,
		contenteditable,
		ModalBar,
	},
	data() {
		return {
			focus: null,
			keyDownIndex: null,
			emptyTextIndex: null,
			prevTextLength: null,
			itemRefs: [],
		}
	},
	computed: {
		items: {
			get() {
				return this.block.content.paragraphs;
			},
			set() {
			}
		}
	},
	beforeUpdate() {
		this.itemRefs = [];
	},
	methods: {
		blockReordered(items) {
			this.$emit('updateListItems', {id: this.block.id, items: items});
		},
		toggleSettings() {
			this.$emit('toggleSettings', this.block);
		},
		setItemRef(el) {
			if (el) {
				this.itemRefs.push(el);
			}
		},
		addItem(index) {
			this.items.splice(index, 0, {
				value: '',
			});
		},
		binItem(index) {
			if (this.items.length > 1) {
				this.items.splice(index, 1);
			}
		},
		fakeInput() {
			// fake input event to trigger v-model updates
			for(let i in this.itemRefs) {
				this.itemRefs[i].$el.dispatchEvent(new Event('input'));
			}
		},
		checkSelection(e) {
			const sel = window.getSelection();
			let range = sel.getRangeAt(0);

			if (e.target.nodeName === 'BUTTON') {
				// unwrap words
				const p = e.target.parentNode;
				p.replaceWith(p.textContent);
				range.commonAncestorContainer.parentNode.normalize();

				this.fakeInput();

			} else if (range.commonAncestorContainer.parentNode.nodeName === 'STRONG') {
				// prevent nested strong tags
				return;

			} else if (!range.collapsed && range.commonAncestorContainer.nodeType === 3) {
				// wrap whole words
				range = document.createRange();
				range.setStart(sel.anchorNode, sel.anchorOffset);
				range.setEnd(sel.focusNode, sel.focusOffset);

				const direction = (range.collapsed) ? ['backward', 'forward'] : ['forward', 'backward'];

				range.detach();

				const endNode = sel.focusNode, endOffset = sel.focusOffset;
				sel.collapse(sel.anchorNode, sel.anchorOffset);
				sel.modify('move', direction[0], 'character');
				sel.modify('move', direction[1], 'word');
				sel.extend(endNode, endOffset);
				sel.modify('extend', direction[1], 'character');
				sel.modify('extend', direction[0], 'word');

				// wrap in strong tag
				const newParent = document.createElement('strong');
				range = sel.getRangeAt(0);
				range.surroundContents(newParent);
				newParent.append(document.createElement('button'));
				range.collapse(false);

				this.fakeInput();
			}
		},
		onKeydown(event) {
			const range = document.getSelection().getRangeAt(0);
			const text = range.commonAncestorContainer.data || ''; // important
			const index = this.items.findIndex(i => i.value === text);
			const key = event.key.toLowerCase();

			this.keyDownIndex = index;

			if (key === 'enter') {
				// store curent caret position / selection
				let prevStr = text.substr(0, range.startOffset).trim();
				let nextStr = text.substr(range.endOffset, text.length).trim();

				if (range.startOffset === 0) { // start of string
					this.items[index].value = nextStr;
console.log('start of string...');
console.log('commonAncestorContainer:',range.commonAncestorContainer);
console.log('prevStr:',prevStr);
console.log('nextStr:',nextStr);
					this.items.splice(index, 0, { value: '' });

				} else { // mid string
// TODO: breaks with strong tag present
console.log('mid string...');
console.log('commonAncestorContainer:',range.commonAncestorContainer.nodeName);
console.log('prevStr:',prevStr);
console.log('nextStr:',nextStr);

//const fragment = document.createDocumentFragment();
let el = range.commonAncestorContainer.nextSibling;
//let prevNodes = [];
let nextNodes = [];
if (nextStr.length) {
	nextNodes.push(document.createTextNode(nextStr));
}
while (el) {
	nextNodes.push(el);
	el = el.nextSibling;
}
console.log('nextNodes:',nextNodes);
nextStr = '';
for (const node of nextNodes) {
	const n = (node.nodeType === 1) ? node.outerHTML : node.nodeValue;
//	console.log('node:',n);
	nextStr += n;
}
console.log('nextStr:',nextStr);



console.log('prevStr:',prevStr);
					this.items[index].value = prevStr; // doesn't work when <strong> present??
					this.items.splice((index + 1), 0, { value: nextStr }); // doesn't work when <strong> present??
console.log('This is not output??');
				}

			} else if (key === 'backspace') {
				if (range.startOffset === 0 && index > 0) {
					// merge with previous item
					this.items[index-1].value += ' ' + text;
					this.items.splice(index, 1);

					// store previous item text length for caret positioning
					const prevItem = event.currentTarget.parentNode.previousElementSibling.firstElementChild;
					this.prevTextLength = prevItem.innerText.length;
					prevItem.focus();
				}
			}
		},
		onKeyup(event) {
			const sel = window.getSelection();
			const range = sel.getRangeAt(0);
			const text = range.commonAncestorContainer.data;
			const key = event.key.toLowerCase();
			const next = range.commonAncestorContainer.nextSibling;

			if (event.key === 'Backspace' && next && next.nodeName === 'STRONG' && next.childElementCount === 1) {
				// remove empty strong tags
				range.commonAncestorContainer.nextSibling.remove();
				this.fakeInput();

			} else if (range.commonAncestorContainer.nodeName === 'BUTTON') {
				// prevent caret within button
				if (key === 'arrowleft') {
					// left arrow
					sel.modify('move', 'backward', 'character');

				} else if (key === 'arrowright') {
					// right arrow
					sel.modify('move', 'forward', 'character');
				}

//			} else if (range.commonAncestorContainer.parentNode.nodeName === 'STRONG') {
				//const strLen = range.commonAncestorContainer.parentNode.innerText.length;

			} else if (range.commonAncestorContainer.parentNode.nodeName === 'DIV') { // paragraph
				if ((key === 'arrowright' || key === 'arrowdown') && range.commonAncestorContainer.nextSibling === null) {
					// last string
					if (range.endOffset === range.commonAncestorContainer.length) { // end of string
						const liSib = range.commonAncestorContainer.parentNode.parentNode.nextElementSibling; // li.prow

						if (liSib !== null) { // position caret at start of li text node
							liSib.firstElementChild.focus();
						}
					}
				} else if ((key === 'arrowleft' || key === 'arrowup') && range.commonAncestorContainer.previousSibling === null) {
					if (range.startOffset === 0) {
						const liSib = range.commonAncestorContainer.parentNode.parentNode.previousElementSibling; // li.prow

						if (liSib !== null) {
							range.selectNodeContents(liSib.firstElementChild);
							range.collapse(false);
							liSib.firstElementChild.focus();
						}
					}
				}
			}

			range.commonAncestorContainer.parentNode.normalize();

			if (key === 'enter') {
				const nextItem = event.currentTarget.parentNode.nextElementSibling.firstElementChild;
				nextItem.focus();

			} else if (key === 'backspace') {
				if (this.emptyTextIndex === this.keyDownIndex) {
					// item flagged as empty so delete
					this.items.splice(this.emptyTextIndex, 1);

					// reset empty flag
					this.emptyTextIndex = null;

					const prevItem = event.currentTarget.parentNode.previousElementSibling.firstElementChild;

					prevItem.focus();

					// position caret at end of text node
					const range = document.getSelection().getRangeAt(0);
					range.setStart(prevItem.childNodes[0], prevItem.innerText.length);
					range.collapse(true);

				} else if (!text && this.items.length > 1) {
					// flag item as empty
					this.emptyTextIndex = this.keyDownIndex;

				} else {
					if (range.startOffset === 0 && this.keyDownIndex > 0) {
						// position caret at end of text node
						const curItem = event.currentTarget;
						curItem.focus();

						const range = document.getSelection().getRangeAt(0);
						range.setStart(curItem.childNodes[0], this.prevTextLength + 1);
						range.collapse(true);

						this.prevTextLength = null;
					}

				}
//			} else if (key === 'arrowleft') {
//				console.log('arrowleft:',range);
//			} else if (key === 'arrowright') {
//				console.log('arrowright:',range);
			}

			this.$emit('updateListItems', {id: this.block.id, items: this.items});
		},
	},
}
</script>

<style lang="scss">
.completemulti {
	ul {
		overflow: hidden;
		margin: 0 -56px;
		padding: 0 56px; // space for icons
		counter-reset: li;
		li {
			width: 900px;
		}
	}
}
li.prow { // root style so drag state maintains style
	position: relative;
	margin: 20px 0;
	display: flex;
	list-style-type: none;
	div {
		line-height: 1.6;
		strong { // selection
			z-index: 0;
			position: relative;
			padding: 0 10px 0 30px;
			font-weight: normal;
			color: #FFF;
			caret-color: #FFF;
			background: $orange;
			display: inline-block;
			border-radius: 4px;
			button {
				cursor: pointer;
				position: absolute;
				top: 50%;
				left: 5px;
				width: 20px;
				height: 20px;
				margin-top: -10px;
				border-radius: 50%;
				background: inherit;
				caret-color: transparent; // hide caret
				background: url(/img/icon-plus.svg) center no-repeat;
				background-size: 12px 12px;
				transform: rotate(45deg);
				transition: .3s ease-in-out;
				&:hover {
					background-color: $darkgrey;
				}
			}
		}
	}
	svg {
		display: block;
	}
	&:before,
	&:after { // extend hover area over drag
		content: '';
		position: absolute;
		top: 0;
		height: 100%;
	}
	&:before {
		right: 100%;
		width: 30px;
	}
	&:after {
		left: 100%;
		width: 56px;
	}
	.but {
		z-index: 1;
		position: absolute;
		top: 50%;
		opacity: 0;
	}
	.drag {
		left: -10px;
		transform: translate(-100%, -50%);
		cursor: move;
	}
	.bin {
		left: 100%;
		transform: translate(40px, -50%);
		&:disabled {
			cursor: not-allowed;
			.fill {
				fill: #AAA;
			}
		}
	}
	.add {
		left: 100%;
		transform: translate(10px, -50%);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: $orange;
		&:hover {
			background: $darkgrey;
		}
	}
	.bin,
	.drag {
		.fill {
			fill: $orange;
		}
		&:hover {
			.fill {
				fill: $darkgrey;
			}
		}
	}
	&.sorting,
	&:hover {
		>.add,
		>.bin,
		>.drag {
			opacity: 1;
		}
	}
}
</style>
