<template lang="pug">
include ../pug/svg
div.field(v-if="alignHorz!==''" :class="class")
	select(v-model="modelAlignHorz" @change="fieldUpdated")
		option(:value="null") Default
		option(value="txt-left") Left Aligned
		option(value="txt-center") Centered
		option(value="txt-right") Right Aligned
	label Text Alignment / Horz.
	+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
	
div.field(v-if="alignVert!==''" :class="class")
	select(v-model="modelAlignVert" @change="fieldUpdated")
		option(:value="null") Default
		option(value="txttop") Top Aligned
		option(value="txtmiddle") Centered
		option(value="txtbottom") Bottom Aligned
	label Text Alignment / Vert.
	+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
</template>

<script>
export default {
	name: 'FieldAlign',
	props: {
		class: {
			type: String,
			default: '',
		},
		alignHorz: {
			type: String,
			default: '',
		},
		alignVert: {
			type: String,
			default: '',
		},
	},
	emits: ['fieldUpdated','update:alignHorz','update:alignVert'],
	computed: {
		modelAlignHorz: {
			get() {
				return this.alignHorz;
			},
			set(value) {
				this.$emit('update:alignHorz', value);
			}
		},
		modelAlignVert: {
			get() {
				return this.alignVert;
			},
			set(value) {
				this.$emit('update:alignVert', value);
			}
		},
	},
	methods: {
		fieldUpdated() {
			this.$emit('fieldUpdated');
		},
	},
}
</script>

<style lang="scss">
</style>
