<template lang="pug">
include ../pug/svg
Form(ref="formEmbed" :class="{multiple:multiple}" :validation-schema="embedSchema" v-slot="{ errors }")
	fieldset.fgroup(v-for="(embed, index) in selectedEmbed" :key="index" :index="index")
		button.but.bin(v-if="selectedEmbed.length>1" type="button" @click="binEmbed(index)")
			span Delete {{terminology('media','media','singular')}}
			+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
		FieldText.c2(:name="'mediaUrl'+index" :label="terminology('media','media','singular')+' URL'" :required="true" :errors="errors" v-model="embed.mediaUrl" @keyup="fieldUpdated")
		FieldText.c2(:name="'seoAlt'+index" label="Alt Text" :required="false" :errors="errors" v-model="embed.seoAlt" @keyup="fieldUpdated")
	div.foot
		button.but.circ(type="button" v-if="multiple" @click="addEmbed")
			span Add Embed
			+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
		span.req Required
		button.but.pri(type="button" :disabled="!selectedEmbed[0].mediaUrl||errors.length" @click="insertEmbed") Insert
		button.but.sec(type="button" @click="closeEmbedModal") Cancel
</template>
<script>
import { Form } from 'vee-validate';
import * as Yup from 'yup';
import FieldText from '../components/FieldText';

export default {
	name: 'MediaEmbed',
	props: {
		multiple: {
			type: Boolean,
			default: false,
		},
		embedType: {
			type: String,
			validator(type) {
				return ['image', 'video', 'audio'].includes(type);
			},
			default: null,
		},
		selected: {
			type: Array,
			default: () => [],
		},
	},
	emits: ['update:selected','closeEmbedModal','insertEmbed'],
	components: {
		Form,
		FieldText,
	},
	data() {
//		const req = 'Required';
		/*const embedSchema = Yup.object().shape({
			users: yup.array().of(
				yup.object().shape({
					url: Yup.string().url().required(req).nullable(),
					url: Yup.string().nullable(),
				})
			)
//			url: Yup.string().url().required(req).nullable(),
		});*/
		const embedSchema = Yup.object().shape({});
		
		return {
			embedSchema,
			emptyEmbed: [{
				mediaUrl: null,
				seoAlt: null,
			}],
		};
	},
	computed: {
		selectedEmbed() {
			return this.selected.length ? this.selected : [{...this.emptyEmbed[0]}];
		},
	},
	methods: {
		closeEmbedModal() {
			this.$emit('closeEmbedModal');			
		},
		insertEmbed() {
			this.$emit('insertEmbed', this.selectedEmbed);
		},
		fieldUpdated() {
			this.$emit('update:selected', this.selectedEmbed);			
		},
		addEmbed() {
			this.selectedEmbed.push({...this.emptyEmbed[0]}); // copy without reference
			this.fieldUpdated();
		},
		binEmbed(index) {
			if (this.selectedEmbed.length > 1) {
				// remove selection
				this.selectedEmbed.splice(index, 1);				
			}
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
	},
}
</script>

<style lang="scss">
#embedMedia {
	form {
		&.multiple {
			fieldset {
				position: relative;
				&:not(:only-of-type) {
					padding-right: 10px;					
				}
				.bin {
					z-index: 1;
					position: absolute;
					top: 50%;
					right: -20px;
					transform: translateY(-50%);
					margin-top: -10px;
					width: 30px;
					justify-content: center;
					.fill {
						fill: var(--lab-col);
					}
					&:hover {
						.fill {
							fill: var(--pri-bg);
						}
					}
				}
			}
			.circ {
				display: flex;
				margin-left: 0;
				margin-right: 10px;
			}
		}
	}
}
</style>
