<template lang="pug">
include ../pug/svg
div.block(:class="blockClasses" @mouseleave="focus=false" :style="blockStyles")
	contenteditable(tag="div" :spellcheck="(focus===true)" :class="{fade:focus}" v-model="block.content.onecolumn" :noNL="false" :noHTML="false" @focus="focus=true" @blur="focus=false" @keyup="blockUpdated")
	div.modal.settings(v-if="block.settings.active")
		ModalBar(label="Block Settings" :useDrag="true" @close="toggleSettings()")
		form.body
			fieldset.fgroup
				FieldAlign(
					class="c2"
					v-model:alignHorz="block.settings.alignHorz"
					@fieldUpdated="blockUpdated")
				FieldPadding(
					class="c2"
					v-model:padTop="block.settings.padTop"
					v-model:padBottom="block.settings.padBottom" 
					@fieldUpdated="blockUpdated")
				FieldBackgroundColour(
					class="c2"
					v-model:bgColor="block.settings.bgColor"
					v-model:whiteText="block.settings.whiteText" 
					@fieldUpdated="blockUpdated")
				FieldRatio.c2(
					v-model:ratio="block.settings.minRatio"
					label="Minimum Block Ratio"
					@fieldUpdated="blockUpdated")
			div.foot
				span.req Required
				button.but.pri(type="button" @click="toggleSettings()") Apply
				button.but.sec(type="button" @click="resetSettings()") Reset
	//-pre {{block.settings}}
</template>

<script>
import contenteditable from 'vue-contenteditable';
import FieldBackgroundColour from '../components/FieldBackgroundColour';
import FieldPadding from '../components/FieldPadding';
import FieldAlign from '../components/FieldAlign';
import FieldRatio from '../components/FieldRatio';
import ModalBar from '../components/ModalBar';

export default {
	name: 'BlockTextOneColumn',
	props:['block'],
	emits: ['blockUpdated', 'toggleSettings', 'resetSettings'],
	components: {
		contenteditable,
		ModalBar,
		FieldBackgroundColour,
		FieldPadding,
		FieldAlign,
		FieldRatio,
	},
	data() {
		return {
			focus: false,
			nullValue: null,
		}
	},
	computed: {
		settings() {
			return this.block.settings;
		},
		blockStyles() {
			let styles = [];

			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}

			return styles.join(';');
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];

			if (this.settings.alignHorz) {
				classes.push(this.settings.alignHorz);
			}

			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}

			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}

			if (this.settings.whiteText) {
				classes.push('whitetext');
			}
			
			return classes.join(' ');
		},
	},
	methods: {
		blockUpdated() {
			this.$emit('blockUpdated');
		},
		toggleSettings() {
			this.$emit('toggleSettings', this.block);
		},
		resetSettings() {
			this.$emit('resetSettings', this.block);
		},
	},
}
</script>

<style lang="scss">
</style>
