<template lang="pug">
include ../pug/svg
div.block(:class="[block.type, block.subtype]" @mouseleave="focus=false")
	contenteditable(tag="div" :spellcheck="(focus===true)" :class="{fade:focus}" v-model="block.content.onecolumn" :noNL="false" @focus="focus=true" @blur="focus=false" @keyup="blockUpdated")
	div.modal.settings(v-if="block.settings.active" @mouseleave="toggleSettings()")
		ModalBar(label="Block Settings" :useDrag="true" @close="toggleSettings()")
		pre {{block.settings}}
</template>

<script>
import contenteditable from 'vue-contenteditable';
import ModalBar from '../components/ModalBar';

export default {
	name: 'BlockTextNote',
	props:['block'],
	emits: ['blockUpdated', 'toggleSettings'],
	components: {
		contenteditable,
		ModalBar,
	},
	data() {
		return {
			focus: false,
		}
	},
	methods: {
		blockUpdated() {
			this.$emit('blockUpdated');
		},
		toggleSettings() {
			this.$emit('toggleSettings', this.block);
		},
	},
}
</script>

<style lang="scss">
.note {}
</style>
