<template lang="pug">
include ../pug/svg
div.field
	select(v-model="modelRatio" @change="fieldUpdated")
		option(:value="null") Natural
		option(value="ratio-fullheight") Full Height
		option(value="ratio-square") Square
		option(value="ratio-classic") Classic
		option(value="ratio-camera") Camera
		option(value="ratio-widescreen") Widescreen
		option(value="ratio-cinema") Cinema
	label {{label}}
	+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
</template>

<script>
export default {
	name: 'FieldRatio',
	props: {
		ratio: {
			type: String,
		},
		label: {
			type: String,
			default: 'Ratio',
		},
	},
	emits: ['fieldUpdated','update:ratio'],
	computed: {
		modelRatio: {
			get() {
				return this.ratio;
			},
			set(value) {
				this.$emit('update:ratio', value);
			}
		},
	},
	methods: {
		fieldUpdated() {
			this.$emit('fieldUpdated');
		},
	},
}
</script>

<style lang="scss">
</style>
