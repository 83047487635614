<template lang="pug">
include ../pug/svg
div.block(:class="blockClasses" @mouseleave="focus=null" :style="blockStyles")
	//-AudioPlayer(:media="audio.media")
	audio(:src="audioSrc" :class="audioClasses" @load="audioChanged" preload="auto" controls)
	div.butdbl.text(:class="{unselected : !audioSrc}")
		button.but(type="button" @click="selectMedia()") Media
		//-button.but(type="button" @click="removeMedia()")
			span Remove Media
			+svg(svg-filename="iconBin")
		button.but(type="button" @click="embedMedia()") Embed
	//-pre {{block}}
	div.modal.settings(v-if="block.settings.active")
		ModalBar(label="Block Settings" :useDrag="true" @close="toggleSettings()")
		form.body
			fieldset.fgroup
				FieldColour.c2(
					label="Background Colour"
					v-model:colour="block.settings.bgColor",
					@fieldUpdated="blockUpdated")
				FieldPadding(
					class="c2"
					v-model:padTop="block.settings.padTop"
					v-model:padBottom="block.settings.padBottom" 
					@fieldUpdated="blockUpdated")
			div.foot
				span.req Required
				button.but.sec(type="button" @click="resetSettings()") Use Defaults</template>

<script>
import contenteditable from 'vue-contenteditable';
//-import AudioPlayer from '../components/AudioPlayer';
import FieldPadding from '../components/FieldPadding';
import FieldColour from '../components/FieldColour';
import ModalBar from '../components/ModalBar';

export default {
	name: 'BlockStreamAudio',
	props:['block'],
	emits: ['blockUpdated', 'toggleSettings', 'resetSettings', 'showMediaModal', 'showEmbedModal', 'removeMedia'],
	components: {
		contenteditable,
		FieldPadding,
		FieldColour,
		ModalBar,
		//AudioPlayer,
	},
	data() {
		return {
			focus: null,
			showMediaModal: false,
			mediaType: 'audio',
		}
	},
	computed: {
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];
			
			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}
			
			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}
			
			return classes.join(' ');
		},
		blockStyles() {
			let styles = [];
			
			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}
			
			return styles.join(';');
		},
		audioClasses() {
			let classes = [];

			if (this.audioSrc) {
				classes.push('show');
			}

			return classes.join(' ');
		},
		audio() {
			return this.block.content.audios[0];
		},
		audioSrc() {
			if (this.audio.media) {
				return this.audio.media.mediaUrl;
			} else if (this.audio.embed) {
				return this.audio.embed.mediaUrl;
			}
			
			return null;
		},
	},
	methods: {
		blockUpdated() {
			this.$emit('blockUpdated');
		},
		toggleSettings() {
			this.$emit('toggleSettings', this.block);
		},
		resetSettings() {
			this.$emit('resetSettings', this.block);
		},
		selectMedia() {
			// pass media type and block for insertion
			this.$emit('showMediaModal', {
				mediaType: this.mediaType,
				block: this.block,
				insertIndex: 0,
			});
		},
		embedMedia() {
			// pass media type and block for insertion
			this.$emit('showEmbedModal', {
				mediaType: this.mediaType,
				block: this.block,
				insertIndex: 0,
			});
		},
		removeMedia() {
			this.$emit('removeMedia', {
				block: this.block,
				index: 0,
			});
		},
		uploadMedia() {
		},
		audioChanged() {
			this.blockUpdated();
		},
	},
}
</script>
<style lang="scss">
</style>
