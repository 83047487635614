<template lang="pug">
include ../pug/svg
div#lesson(v-if="lesson")
	//-div(style="position:fixed;top:0;left:0;width:300px;background:rgba(255,255,255,.7);z-index:1000;overflow:scroll;height:500px;")
		pre mediaModal: {{mediaModal}}
	nav.navbar
		+svg(svg-filename="iconBlock" aria-hidden="true" alt="Blocks icon")
		dl(:class="{hide:lessonContent.value.length}")
			template(v-for="(type, i) in blockTypes" :index="i" :key="type.key")
				dt(:class="{active:blockTypeActive===type.key}" @click="toggleBlockType(type.key)")
					a {{type.name}}
				dd.modal.center
					//-@mouseleave="toggleBlockType(null)"
					div.bar
						+svg(svg-filename="iconMore" aria-hidden="true")
						span(id="tb") {{type.name}} Blocks
						//-button.but(type="button" @click="blockTypeIndex=null") Close
						button.but(type="button" @click="toggleBlockType(null)") Close
							+svg(svg-filename="iconClose")
					div.body
						ul(aria-labelledby="tb")
							li(v-for="(subtype, z) in type.subtypes" :index="z" :key="subtype.key")
								figure
									figcaption {{subtype.name}}
									picture
										img(:src="thumbBase+subtype.thumb")
									button.but.circ.plus(type="button" aria-label="'Add '+subtype.name+' block'" @click="addBlock(type, subtype)") Add Block
										+svg(svg-filename="iconPlus")
										
	SlickList#block(tag="ol" lockAxis="y" v-model:list="lessonContent.value" useDragHandle=true helperClass="sorting" useWindowAsScrollContainer=true @sort-start="sorting=true" @update:list="updateStore" :class="{sorting:sorting}" appendTo="#lesson")

		SlickItem.wrap(v-for="(block, i) in lessonContent.value" tag="li" :index="i" :key="block.id" :class="[block.settings?.minRatio]" :id="'block'+block.id")
			nav.buttonbar(:class="{active:blockPrependActive===block.id}" @mouseleave="blockPrependActive=null")
				button.but.circ.plus(type="button" @click="blockPrependActive=block.id")
					+svg(svg-filename="iconBlock" aria-hidden="true" alt="Blocks icon")
					span Prepend block
				ul
					li(v-for="(type, i) in blockTypes" :index="i" :key="type.key" :class="{active:blockTypeActive===type.key}" )
						//-a(@click="toggleBlockType(type.key)" role="button") {{type.name}}
						a(@click="prependBlock(type.key, block.id)" role="button") {{type.name}}
			ul.configbar
				li
					button.but.drag(type="button" v-handle)
						+svg(svg-filename="iconDrag" aria-hidden="true" alt="Drag icon")
						span Reorder block
				li
					button.but(type="button" @click="toggleSwitch(block.id)")
						+svg(svg-filename="iconSwitch" aria-hidden="true" alt="Switch icon")
						span Switch {{block.type}} block
				li(v-if="hasSettings(block)")
					button.but(type="button" @click="toggleSettings(block)")
						+svg(svg-filename="iconCog" aria-hidden="true" alt="Cog icon")
						span Edit settings
				li
					button.but(type="button" @click="binBlock(block)")
						+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
						span Delete block
			nav.buttonbar(:class="{active:blockAppendActive===block.id}" @mouseleave="blockAppendActive=null")
				button.but.circ.plus(type="button" @click="blockAppendActive=block.id")
					+svg(svg-filename="iconBlock" aria-hidden="true" alt="Blocks icon")
					span Append block
				ul
					li(v-for="(type, i) in blockTypes" :index="i" :key="type.key" :class="{active:blockTypeActive===type.key}" )
						//-a(@click="toggleBlockType(type.key)" role="button") {{type.name}}
						a(@click="appendBlock(type.key, block.id)" role="button") {{type.name}}
			component(:is="blockComponent(block)" :course="course" :block="syncBlock(block)" @blockUpdated="updateStore" @updateListItems="updateListItems" @showMediaModal="openMediaModal" @showEmbedModal="openEmbedModal" @removeMedia="removeMedia" @toggleSettings="toggleSettings" @resetSettings="resetSettings")
			//-div.modal.switchtype(v-if="switchActive===block.id" @mouseleave="toggleSwitch(null)")
			div.modal.switchtype(v-if="switchActive===block.id")
				ModalBar(:label="'Switch '+block.type+' Block'" :useDrag="true" @close="toggleSwitch(null)")
				div.body
					dl
						template(v-for="(subtype, z) in getSubtypes(block.type)" :index="z" :key="subtype.key")
							dt(:class="{active:subtype.key===block.subtype}")
								a(role="button" aria-label="'Swap to '+subtype.name+' block'" @click="switchSubtype(block, subtype)") {{subtype.name}}
							dd
								picture
									img(:src="thumbBase+subtype.thumb")
	div#insertMedia.modal.center(:class="{active:showMediaModal}")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span Insert {{terminology('media','media','singular')}}
			button.but(type="button" @click="closeMediaModal") Close
				+svg(svg-filename="iconClose")
		div.body(v-if="mediaModal.data")
			MediaGrid(:modal="true" :mediaType="mediaModal.data.block.rules.mediaType" :multiple="mediaModal.data.block.rules.multiple" v-model:selected="mediaModal.selected" @insertMedia="insertMedia('media')" @closeModal="closeMediaModal")
			
	div#embedMedia.modal.center(:class="{active:showEmbedModal}")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span Embed {{terminology('media','media','singular')}}
			button.but(type="button" @click="closeMediaModal") Close
				+svg(svg-filename="iconClose")
		div.body(v-if="mediaModal.data")
			MediaEmbed(:mediaType="mediaModal.data.block.rules.mediaType" :multiple="mediaModal.data.block.rules.multiple" v-model:selected="mediaModal.selected" @insertEmbed="insertMedia('embed')" @closeEmbedModal="closeMediaModal")
</template>

<script>
import { ref } from "vue";
import { Form, Field, ErrorMessage } from 'vee-validate';
//import * as Yup from 'yup';
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort';
import { mapFields } from 'vuex-map-fields';
import ModalBar from '../components/ModalBar';
//import MediaModal from '../components/MediaModal';
import MediaGrid from '../components/MediaGrid';
import MediaEmbed from '../components/MediaEmbed';
//import FieldText from '../components/FieldText';
import BlockTextHeaderGroup from '../components/BlockTextHeaderGroup';
import BlockTextHeaded from '../components/BlockTextHeaded';
import BlockTextSubheaded from '../components/BlockTextSubheaded';
import BlockTextOneColumn from '../components/BlockTextOneColumn';
import BlockTextTwoColumn from '../components/BlockTextTwoColumn';
import BlockTextStatement from '../components/BlockTextStatement';
import BlockTextQuote from '../components/BlockTextQuote';
import BlockTextNote from '../components/BlockTextNote';
import BlockListBulleted from '../components/BlockListBulleted';
import BlockListNumbered from '../components/BlockListNumbered';
//import BlockListAccordion from '../components/BlockListAccordion';
import BlockImageSingle from '../components/BlockImageSingle';
import BlockImageCarousel from '../components/BlockImageCarousel';
import BlockImageWithText from '../components/BlockImageWithText';
import BlockImageOverText from '../components/BlockImageOverText';
import BlockStreamVideo from '../components/BlockStreamVideo';
import BlockStreamAudio from '../components/BlockStreamAudio';
import BlockTaskCheckbox from '../components/BlockTaskCheckbox';
import BlockTaskComplete from '../components/BlockTaskComplete';
//import BlockTaskFeedback from '../components/BlockTaskFeedback';
import BlockTaskFreeform from '../components/BlockTaskFreeform';
import BlockDividerNumbered from '../components/BlockDividerNumbered';

export default {
	name: 'ManageLearningCourseLesson', // accessed via ManageLearningCourse not ManageLearningCourseContent
	props: ['course', 'cancelled'],
	emits: ['storeUpdated'],
	directives: { handle: HandleDirective },
	components: {
		Form,
		Field,
		ErrorMessage,
		SlickList,
		SlickItem,
		ModalBar,
		MediaGrid,
//		MediaModal,
		MediaEmbed,
		//FieldText,
		BlockTextHeaderGroup,
		BlockTextHeaded,
		BlockTextSubheaded,
		BlockTextOneColumn,
		BlockTextTwoColumn,
		BlockTextStatement,
		BlockTextQuote,
		BlockTextNote,
		BlockListBulleted,
		BlockListNumbered,
//		BlockListAccordion,
		BlockImageSingle,
		BlockImageCarousel,
		BlockImageWithText,
		BlockImageOverText,
//		BlockStreamVideoFullwidth,
		BlockStreamVideo,
		BlockStreamAudio,
		BlockTaskCheckbox,
		BlockTaskComplete,
//		BlockTaskFeedback,
		BlockTaskFreeform,
		BlockDividerNumbered,
	},
	created() {
		window.addEventListener('keydown', this.onKeyDown);
		window.addEventListener('paste', this.onPaste);
	},
	mounted() {
		const name = this.lesson?.name || 'Untitled';
		
		this.$store.dispatch('gui/setHeader', {
			title: 'Content - ' + name,
			backRoute: {
				text: 'Content',
				name: 'ManageLearningCourseContent',
				params: {courseId:this.course.id},
			},
		});
	},
	watch: {
		course: {
			handler(course) {
				this.$store.dispatch('gui/setHeader', {
					title: course.name,
					backRoute: {
						text: this.terminology('course','course','plural'),
						name: 'ManageLearning',
					},			
				});		
			},
			deep: false,
		},
	},
	computed: {
		...mapFields('gui', [ // get/set vuex store
			'showMediaModal',
			'showEmbedModal',
			'tintState',
		]),
		lesson() {
			return (this.course?.courseLessons) ? this.course.courseLessons.find(l => l.id === parseInt(this.$route.params.lessonId)) : null;
		},
		lessonContent() {
			// order course content by rank
			let blocks = this.lesson.blockData?.blocks || [];
			blocks = JSON.parse(JSON.stringify(blocks));
			return ref(blocks);
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		hasSettings(block) {
			const blocktype = this.blockTypes.find(t => t.key === block.type);
			const settings = blocktype.subtypes.find(s => s.key === block.subtype).settings;
			
			return Object.keys(settings).length > 1;
		},
		openMediaModal(data) {
			this.mediaModal.data = data;
			this.showMediaModal = true;
			this.tintState = true;
		},
		openEmbedModal(data) {
			this.mediaModal.data = data;
			this.showEmbedModal = true;
			this.tintState = true;
		},
		closeMediaModal() {
			this.mediaModal.selected = [];
			this.mediaModal.data = null;
			this.showEmbedModal = false;
			this.showMediaModal = false;
			this.tintState = false;
		},
		insertMedia(key) {
			// get block data
			const {block, insertIndex} = this.mediaModal.data;
			
			if (insertIndex === 'background') {
				// block background
				block.settings.bgImage = {
					media: (key === 'media') ? this.mediaModal.selected[0] : null,
					embed: (key === 'embed') ? this.mediaModal.selected[0] : null,
					positionX: 100,
					positionY: 50,
				};
				
				this.updateStore();
				this.toggleSettings(block);
				
			} else {
				// block content
				const arrKey = block.rules.mediaType + 's';
				let i, remove;
				
				if (block.rules.multiple && (block.content[arrKey][insertIndex].media||block.content[arrKey][insertIndex].embed)) {
					// existing media - insert all after index
					i=1;
					remove = 0;
				} else {
					// empty media - insert at index
					i=0;
					remove = 1;
				}
				
				for (let obj of this.mediaModal.selected) {
					const index = insertIndex + i;
					
					let data = {
						media: (key === 'media') ? obj : null,
						embed: (key === 'embed') ? obj : null,
					};
					
					let param;
					
					if (block.rules.mediaType === 'image' || block.rules.mediaType === 'video') {
						param = (block.rules.mediaType === 'image') ? block.content.images : block.content.videos;
						data.positionX = 50;
						data.positionY = 50;
						
						if (key === 'media') {
							data.figcaption = obj.seoDescription;							
						}
						
					} else if (block.rules.mediaType === 'audio') {
						param = block.content.audios;
					}
					
					param.splice(index, remove, data);
					
					i++;
				}
				
				this.updateStore();
			}
			
			this.closeMediaModal();
		},
		removeMedia({block, index}) {
			if (index === 'background') {
				block.settings.bgImage = {
					media: null,
					embed: null,
					positionX: 50,
					positionY: 50,
				};
				
			} else {
				let param;
				
				if (block.rules.mediaType === 'image') {
					param = block.content.images;
				} else if (block.rules.mediaType === 'video') {
					param = block.content.videos;
				} else if (block.rules.mediaType === 'audio') {
					param = block.content.audio;
				}
				
				if (param.length > 1) {
					param.splice(index, 1);
				} else {
					// placeholder
					param[index] = {
						media: null,
						embed: null,
						positionX: 50,
						positionY: 50,
						figcaption: null,
					}
				}
			}
			
			this.updateStore();
		},
		updateStore() {
			this.sorting = false;
			
			this.$store.dispatch('courses/setCourseLessonBlocks', {
				cId: this.course.id,
				lId: this.lesson.id,
				blocks: this.lessonContent.value
			});
			
			this.$emit('storeUpdated', {valid:true});
		},
		updateListItems({id, items}) {
			// emitted from list block components
			const index = this.lessonContent.value.findIndex(b => b.id === id);
			
			if (this.lessonContent.value[index].type === 'task') {
				const subtype = this.lessonContent.value[index].subtype;
				
				if (subtype === 'checkbox' || subtype === 'radio') {
					this.lessonContent.value[index].content.answers = items;
					
				} else if (subtype === 'complete') {
					this.lessonContent.value[index].content.paragraphs = items;
				}
				
			} else {
				this.lessonContent.value[index].content.items = items;
			}
			
			this.updateStore();
		},
		getSubtypes(type) {
			return this.blockTypes.find(t => t.key === type).subtypes;
		},
		getSubtype(type, subtype) {
			const subtypes = this.getSubtypes(type);
			return subtypes.find(t => t.key === subtype);
		},
		syncBlock(block) {
			// sync block incase any settings were added since block was last saved
			const subtype = this.getSubtype(block.type, block.subtype);
			
			for (const setting in subtype.settings) {
				if ( !(setting in block.settings) ) {
					block.settings[setting] = subtype.settings[setting];
				}
			}
			for (const content in subtype.content) {
				if ( !(content in block.content) ) {
					block.content[content] = subtype.content[content];
				}
			}
			
			return block;
		},
		onKeyDown(event) {
			// prevent pasting into contenteditable component
			if (!event.target.hasAttribute('contenteditable') || event.target.getAttribute('contenteditable') !== 'true') {
				return;
			}
			
			// disable formatting shortcuts (bold, italic etc.)
			const allowedMetaKeys = ['z','x','c','v'];
			
			if (event.metaKey && !allowedMetaKeys.includes(event.key.toLowerCase())) {
				event.preventDefault();
				event.stopPropagation();
			}
		},
		autoIncId() {
			// assign non-clashing id
			let id = 1;
			let ids = this.lessonContent.value.map(b => b.id);
			
			// sort numerically ascending
			ids.sort(function(a, b){return a-b});
			
			for (let i=0; i < ids.length; i++) {
				if (id === ids[i]) {
					id ++;
				} else {
					break;
				}
			}
			
			return id;
		},
		blockComponent(block) {
			const type = this.blockTypes.find(t => t.key === block.type);
			const subtype = type.subtypes.find(s => s.key === block.subtype);
			
			return subtype.component;
		},
		binBlock(block) {
			// remove
			const index = this.lessonContent.value.findIndex(b => b.id === block.id);
			this.lessonContent.value.splice(index, 1);
			
			this.updateStore();
		},
		addBlock(type, subtype) {
			let index;
			
			if (this.blockPrependBefore !== null) {
				// buttonbar prepend insert
				index = this.lessonContent.value.findIndex(b => b.id === this.blockPrependBefore);
				
			} else if (this.blockAppendAfter !== null) {
				// buttonbar append insert
				index = this.lessonContent.value.findIndex(b => b.id === this.blockAppendAfter);
				index ++;
				
			} else {
				// navbar insert
				index = 0;
			}
			
			const block = {
				id: this.autoIncId(),
				type: type.key,
				subtype: subtype.key,
				content: subtype.content,
				rules: subtype.rules,
				settings: subtype.settings,
			};
			
			this.lessonContent.value.splice(index, 0, block);
			
			this.toggleBlockType(null);
			
			this.updateStore();
		},
		prependBlock(typeKey, blockId) {
			this.blockPrependBefore = blockId;
			this.blockAppendAfter = null;
			// display block subtype modal
			this.toggleBlockType(typeKey);
		},
		appendBlock(typeKey, blockId) {
			this.blockPrependBefore = null;
			this.blockAppendAfter = blockId;
			// display block subtype modal
			this.toggleBlockType(typeKey);
		},
		toggleBlockType(type) {
			this.blockTypeActive = (this.blockTypeActive===type) ? null : type;
			// tint background
			const bool = (this.blockTypeActive) ? true : false;
			this.tintState = bool;
		},
		toggleSwitch(blockId) {
			this.switchActive = (this.switchActive===blockId) ? null : blockId;
			// tint background
			const bool = (this.switchActive) ? true : false;
			this.tintState = bool;
		},
		toggleSettings(block) {
			// tint background and toggle block settings
			const bool = !block.settings.active;
			this.tintState = bool;
			this.$store.dispatch('courses/toggleCourseLessonBlockSettings', {
				cId: this.course.id,
				lId: this.lesson.id,
				bId: block.id,
				active: bool,
			});
		},
		resetSettings(block) {
			const blocktype = this.blockTypes.find(t => t.key === block.type);
			const settings = blocktype.subtypes.find(s => s.key === block.subtype).settings;
			
			block.settings = {
				...settings, // reset to default
			}
			
			this.tintState = false;
			this.updateStore();
		},
		switchSubtype(block, subtype) {
			// maintain block content/settings (for potential repopulation on switch back)
			block.content = {
				...subtype.content,
				...block.content, // values maintained
			}
			
			block.rules = {
				...subtype.rules, // apply new rules
			}
			
			block.settings = {
				...subtype.settings,
				...block.settings, // values maintained
			}
			
			block.subtype = subtype.key;
			
			this.tintState = true;
			this.switchActive = null;
			
			this.updateStore();
		},
	},
	data() {
		const animations = ['fade','scale'];
		const positions = [0,10,20,30,40,50,60,70,80,90,100]; // slider??
		const ratios = [
			{
				value: 'ratio-21x9',
				option: 'Cinema',
			},
			{
				value: 'ratio1-6x9',
				option: 'Widescreen',
			},
			{
				value: 'ratio-4x3',
				option: 'Camera',
			},
			{
				value: 'ratio-3x2',
				option: 'Classic',
			},
			{
				value: 'ratio-1x1',
				option: 'Square',
			},
		];
		const blockTypes = [
			{
				key: 'text',
				name: 'Text',
				subtypes: [
					{
						key: 'headergroup',
						name: 'Header Group Text',
						thumb: 'text-headergroup.svg',
						component: 'BlockTextHeaderGroup',
						content: {
							headed: 'Heading Text',
							subheaded: 'Subheading Text',
							onecolumn: 'Body text.',
							/*background: {
								media: null,
								embed: null,
								positionX: 50,
								positionY: 50,
							},*/
						},
						settings: {
							active: false,
							minRatio: null,
							alignHorz: null,
							padTop: null,
							padBottom: null,
							bgColor: null,
							whiteText: false,
							bgImage: {
								media: null,
								embed: null,
								positionX: 50,
								positionY: 50,
							},
							icon: {
								name: null,
								color: null,
							},
							subheadedFirst: false,
						},
						rules: { // non editable
							mediaType: 'image',
							multiple: false,
						},
					},
					{
						key: 'headed',
						name: 'Headed Text',
						thumb: 'text-headed.svg',
						component: 'BlockTextHeaded',
						content: {
							headed: 'Heading Text',
							onecolumn: 'Body text.',
						},
						settings: {
							active: false,
							minRatio: null,
							alignHorz: null,
							padTop: null,
							padBottom: null,
							bgColor: null,
							whiteText: false,
							icon: {
								name: null,
								color: null,
							},
						},
						rules: {
						},
					},
					{
						key: 'subheaded',
						name: 'Subheaded Text',
						thumb: 'text-subheaded.svg',
						component: 'BlockTextSubheaded',
						content: {
							subheaded: 'Subheading Text',
							onecolumn: 'Body text.',
						},
						settings: {
							active: false,
							minRatio: null,
							alignHorz: null,
							padTop: null,
							padBottom: null,
							bgColor: null,
							whiteText: false,
						},
						rules: {
						},
					},
					{
						key: 'onecolumn',
						name: 'Text',
						thumb: 'text-text.svg',
						component: 'BlockTextOneColumn',
						content: {
							onecolumn: 'Body text.',
						},
						settings: {
							active: false,
							minRatio: null,
							alignHorz: null,
							padTop: null,
							padBottom: null,
							bgColor: null,
							whiteText: false,
						},
						rules: {
						},
					},
					/*{
						key: 'twocolumn',
						name: 'Two Column Text',
						thumb: 'text-twocolumn.svg',
						component: 'BlockTextTwoColumn',
						content: {
							onecolumn: 'Body text.',
							twocolumn: 'Body text.',
						},
						settings: {
							active: false,
							minRatio: null,
							alignHorz: null,
							padTop: null,
							padBottom: null,
							bgColor: null,
						},
						rules: {
						},
					},
					{
						key: 'statement',
						name: 'Statement',
						thumb: 'text-statement.svg',
						component: 'BlockTextStatement',
						content: {
							onecolumn: 'Statement text.',
							twocolumn: 'Statement Author.',
						},
						settings: {
							active: false,
							padTop: null,
							padBottom: null,
							bgColor: null,
						},
						rules: {
						},
					},
					{
						key: 'quote',
						name: 'Quote',
						thumb: 'text-quote.svg',
						component: 'BlockTextQuote',
						content: {
							onecolumn: 'Quote text.',
							twocolumn: 'Quote Author.',
						},
						settings: {
							active: false,
							padTop: null,
							padBottom: null,
							bgColor: null,
						},
						rules: {
						},
					},
					{
						key: 'note',
						name: 'Note',
						thumb: 'text-note.svg',
						component: 'BlockTextNote',
						content: {
							onecolumn: 'Note text.',
						},
						settings: {
							active: false,
							padTop: null,
							padBottom: null,
							bgColor: null,
						},
						rules: {
						},
					},*/
				],
			},
			{
				key: 'list',
				name: 'List',
				subtypes: [
					{
						key: 'bulleted',
						name: 'Bulleted List',
						thumb: 'list-bulleted.svg',
						component: 'BlockListBulleted',
						content: {
							items: [
								{value:'Bulleted list item one'},
								{value:'Bulleted list item two'},
//								{value:'Bulleted list item three'},
							],
						},
						settings: {
							active: false,
							minRatio: null,
							alignHorz: null,
							padTop: null,
							padBottom: null,
							bgColor: null,
							whiteText: false,
						},
						rules: {
						},
					},
					{
						key: 'numbered',
						name: 'Numbered List',
						thumb: 'list-numbered.svg',
						component: 'BlockListNumbered',
						content: {
							items: [
								{value:'Numbered list item one'},
								{value:'Numbered list item two'},
//								{value:'Numbered list item three'},
							],
						},
						settings: {
							active: false,
							minRatio: null,
							alignHorz: null,
							padTop: null,
							padBottom: null,
							bgColor: null,
							whiteText: false,
						},
						rules: {
						},
					},
/*
					{
						key: 'accordion',
						name: 'Accordion',
						thumb: 'list-accordion.svg',
						component: 'BlockListAccordion',
						content: {
							items: [
								{
									title:'Accordion title',
									value:'Accordion content'
								},
							],
						},
						settings: {
							active: false,
							open: false, // default state
							collapse: false, // collapse siblings
							bgColor: null,
						},
						rules: {
						},
					},
*/
				],
			},
			{
				key: 'image',
				name: 'Image',
				subtypes: [
					{
						key: 'single',
						name: 'Single Image',
						thumb: 'image-single.svg',
						component: 'BlockImageSingle',
						content: {
							images: [
								{
									media: null,
									embed: null,
									positionX: 50,
									positionY: 50,
									figcaption: null,
								}
							],
						},
						settings: {
							active: false,
							ratio: 'ratio-cinema',
							fullwidth: false,
							figcaption: true,
							bgColor: null,
							contain: false,
							animation: null,
							// block
							padTop: null,
							padBottom: null,
						},
						rules: { // non editable
							mediaType: 'image',
							multiple: false,
						},
					},
					/*{
						key: 'fullwidth',
						name: 'Full Width Image',
						thumb: 'image-fullwidth.svg',
						component: 'BlockImageSingle',
						content: {
							images: [
								{
									media: null,
									embed: null,
									positionX: 50,
									positionY: 50,
									figcaption: null,
								}
							],
						},
						settings: {
							active: false,
							ratio: 'ratio-cinema',
							fullwidth: false,
							figcaption: true,
							animation: null,
						},
						rules: { // non editable
							mediaType: 'image',
							multiple: false,
						},
					},*/
					{
						key: 'carousel',
						name: 'Image Carousel',
						thumb: 'image-carousel.svg',
						component: 'BlockImageCarousel',
						content: {
							images: [
								{
									media: null,
									embed: null,
									positionX: 50,
									positionY: 50,
									figcaption: null,
								},
							],
						},
						settings: {
							active: false,
							ratio: 'ratio-cinema',
							fullwidth: false,
							figcaption: true,
							bgColor: null,
							contain: false,
							animation: null,
							// block
							padTop: null,
							padBottom: null,
						},
						rules: { // non editable
							mediaType: 'image',
							multiple: true,
						},
					},
					/*{
						key: 'grid',
						name: 'Image Grid',
						thumb: 'image-grid.svg'
					},*/
					{
						key: 'withtext',
						name: 'Image With Text',
						thumb: 'image-withtext.svg',
						component: 'BlockImageWithText',
						content: {
							headed: 'Heading Text',
							subheaded: 'Subheading Text',
							onecolumn: 'Body text.',
							images: [
								{
									media: null,
									embed: null,
									positionX: 50,
									positionY: 50,
									figcaption: null,
								},
							],
						},
						settings: {
							active: false,							
							// text settings
							alignHorz: null,
							alignVert: null,
							bgColor: null,
							whiteText: false,
							useHeader: true,
							useSubheader: true,
							subheadedFirst: false,
							// image settings
							ratio: 'ratio-cinema',
							figcaption: true,
							imageLocation: 'right',
							cover: false,
							contain: false,
							animation: null,
							// block
							padTop: null,
							padBottom: null,
						},
						rules: { // non editable
							mediaType: 'image',
							multiple: false,
						},
					},
					/*{
						key: 'overtext',
						name: 'Text Over Image',
						thumb: 'image-overtext.svg',
						component: 'BlockImageOverText',
						content: {
							headed: 'Heading Text',
							subheaded: 'Subheading Text',
							onecolumn: 'Body text.',
							images: [
								{
									media: null,
									embed: null,
									positionX: 50,
									positionY: 50,
									figcaption: null,
								},
							],
						},
						settings: {
							active: false,
							// text settings
							padTop: null,
							padBottom: null,
							bgColor: null,
							subheadedFirst: false,
							// image settings
							figcaption: true,
							ratio: 'ratio-classic',
							animation: null,
						},
						rules: { // non editable
							mediaType: 'image',
							multiple: false,
						},
					},*/
				],
			},
			{
				key: 'stream',
				name: 'Stream',
				subtypes: [
					{
						key: 'video',
						name: 'Video',
						thumb: 'stream-video.svg',
						component: 'BlockStreamVideo',
						content: {
							videos: [
								{
									media: null,
									embed: null,
									positionX: 50,
									positionY: 50,
									figcaption: null,
								}
							],
						},
						settings: {
							active: false,
							ratio: 'ratio-cinema',
							fullwidth: false,
							figcaption: true,
							animation: null,
							// block
							bgColor: null,
							padTop: null,
							padBottom: null,
						},
						rules: { // non editable
							mediaType: 'video',
							multiple: false,
						},
					},
					{
						key: 'audio',
						name: 'Audio',
						thumb: 'stream-audio.svg',
						component: 'BlockStreamAudio',
						content: {
							audios: [
								{
									media: null,
									embed: null,
								}
							],
						},
						settings: {
							active: false,
							// block
							bgColor: null,
							padTop: null,
							padBottom: null,
						},
						rules: { // non editable
							mediaType: 'audio',
							multiple: false,
						},
					},
				],
			},
			{
				key: 'task',
				name: 'Task',
				subtypes: [
/*					{
						key: 'feedback',
						name: 'Feedback',
						thumb: 'task-feedback.svg',
						component: 'BlockTaskFeedback',
						content: {
							rating: 0,
							feedback: null,
						},
						settings: {
							active: false,
							marked: false,
//							minRatio: null,
							alignHorz: null,
						},
						rules: { // non editable
							marked: false,
							multiple: false,
						},
					},	*/
					{
						key: 'radio',
						name: 'Select Single Answer',
						thumb: 'task-radio.svg',
						component: 'BlockTaskCheckbox',
						content: {
							heading: 'Task Heading',
							question: 'Task question?',
							answers: [
								{
									option:'Single answer option one',
//									value:'Answer option one',
									feedback: {
										heading: 'Feedback Heading',
										body: 'Feedback for answer option two',
									},
									correct: true,
								},
								{
									option:'Single answer option two',
//									value:'Answer option two',
									feedback: {
										heading: 'Feedback Heading',
										body: 'Feedback for answer option two',
									},
									correct: false,
								},
							],
							feedback: {
								correct: {
									heading: 'Correct Feedback Heading',
									body: 'Feedback for correct multichoice answer',
								},
								incorrect: {
									heading: 'Incorrect Feedback Heading',
									body: 'Feedback for incorrect multichoice answer',
								},
							},
						},
						settings: {
							active: false,
							examPoints: 1,
							minRatio: null,
							alignHorz: null,
							randomise: false, // randomise answers
							feedback: false, // reveal feedback once selected
							wide: false,
							//padBottom: null,
							//bgColor: null,
						},
						rules: { // non editable
							marked: false,
							multiple: false,
						},
					},
					{
						key: 'checkbox',
						name: 'Select Multiple Answers',
						thumb: 'task-checkbox.svg',
						component: 'BlockTaskCheckbox',
						content: {
							heading: 'Task Heading',
							question: 'Task question?',
							answers: [
								{
									option:'Multiple answer option one',
//									value:'Multiple answer option one',
									feedback: {
										heading: 'Feedback Heading',
										body: 'Feedback for answer option two',
									},
									correct: true,
								},
								{
									option:'Multiple answer option two',
//									value:'Multiple answer option two',
									feedback: {
										heading: 'Feedback Heading',
										body: 'Feedback for answer option two',
									},
									correct: true,
								},
								{
									option:'Multiple answer option three',
//									value:'Multiple answer option three',
									feedback: {
										heading: 'Feedback Heading',
										body: 'Feedback for answer option three',
									},
									correct: false,
								},
							],
							feedback: {
								correct: {
									heading: 'Correct Feedback Heading',
									body: 'Feedback for correct multichoice answer',
								},
								incorrect: {
									heading: 'Incorrect Feedback Heading',
									body: 'Feedback for incorrect multichoice answer',
								},
							},
						},
						settings: {
							active: false,
							examPoints: 1,
							minRatio: null,
							alignHorz: null,
							randomise: false, // randomise answers
							feedback: false, // reveal feedback once selected
							wide: false,
							//padTop: null,
							//padBottom: null,
							//bgColor: null,
						},
						rules: { // non editable
							marked: false,
							multiple: true,
						},
					},
					{
						key: 'freeform',
						name: 'Freeform Answer',
						thumb: 'task-freeform.svg',
						component: 'BlockTaskFreeform',
						content: {
							question: 'Task question?',
							answer: '',
						},
						settings: {
							active: false,
							examPoints: 1,
							minWords: null,
							maxWords: null,
							alignHorz: null,
						},
						rules: {
							marked: true,
						},
					},
					/*{
						key: 'complete',
						name: 'Complete The Sentance',
						thumb: 'task-complete.svg',
						component: 'BlockTaskComplete',
						content: {
							paragraphs: [
								{
									value : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
								},
								{
									value : "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
								},
							],
						},
						settings: {
							examPoints: 1,
							active: false,
							padTop: null,
							padBottom: null,
							bgColor: null,
						},
						rules: {
							marked: false,
						},
					},*/
				],
			},
			/*{
				key: 'reveal',
				name: 'Reveal',
				subtypes: [],
			},*/
			{
				key: 'divider',
				name: 'Divider',
				subtypes: [
					{
						key: 'numbered',
						name: 'Numbered Divider',
						thumb: 'divider-numbered.svg',
						component: 'BlockDividerNumbered',
						content: {
							label: '1',
						},
						settings: false,
						rules: {
						},
					},
				],
			},
		];
		
		return {
			canSave: false,
			canPreview: false,
			canPaste: false,
			thumbBase: '/img/block/',
			positions,
			ratios,
			animations,
			blockTypes,
			id: 0, // temporary block identification
			sorting: false,
			blockTypeActive: null, // block type key
			switchActive: null, // block id
			blockPrependActive: null, // block id
			blockAppendActive: null, // block id
			blockPrependBefore: null,
			blockAppendAfter: null,
			mediaModal: { // stores data temporarily when modal open
				selected: [], // stores selected media/embeds
				data: null, // stores current block data
			},
		}
	},
}
</script>

<style lang="scss">
#th #lesson {
	display: flex;
	flex-direction: column;
	width: 100%;
	.navbar { // main block selector
		z-index: unset;
		position: relative;
		display: inline-flex;
		width: auto;
		margin: 20px auto;
		&:nth-last-child(5) { // blocks added
			display: none;
		}
		&:before {
			z-index: 1;
			content: '';
			background: var(--pan-bar-bg);
			position: absolute;
			top: 0;
			left: 0;
			width: 30px;
			height: 60px;
			border-radius: 8px 0 0 8px;
		}
		>svg {
			z-index: 1;	
			position: absolute;
			top: 50%;
			left: 8px;
			width: 14px;
			height: 14px;				
			margin-top: -7px;
			.fill {
				fill: var(--pan-bar-col);
			}
		}
		dl {
			padding: 0 0 0 30px;
			&.hide {
				height: 0;
				overflow: hidden;
			}
		}
		dt a {
			cursor: pointer;
		}
//		}
		dt.active {
			a:after,
			+ dd {
				opacity: 1;
				visibility: visible;
			}
		}
		dd.modal {
			position: fixed;
			width: calc(100% - 30px);
			ul {
				display: flex;
				flex-wrap: wrap;
				width: calc(100% + 20px);
				margin-left: -20px;
			}
			li {
				@media only screen and (max-width:409px) {
					width: 200px;
					margin: auto;
					figcaption {
						text-align: center;
					}
				}
				@media only screen and (min-width:410px) and (max-width:589px) {
					width: calc(50% - 20px); // 60
					margin-left: 20px;
				}
				@media only screen and (min-width:590px) and (max-width:768px) {
					width: calc(33.33% - 20px);
					margin-left: 20px;
				}
				@media only screen and (min-width:769px) {
					width: calc(25% - 20px); // 60
					margin-left: 20px;
				}
			}
			.plus {
				z-index: 1;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				white-space: nowrap;
				opacity: 0;
			}
			picture {
				position: relative;
				display: block;
box-shadow: 0 0 15px -5px rgba(#000, .2);
				width: 100%;
				overflow: hidden;
				padding-top: 75%;
			}
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				background: #FFF; // leave
				transition: opacity .3s ease-in-out;
			}
			figure {
				position: relative;
				display: table;
				width: 100%;
				&:hover {
					img {
						opacity: .3;
					}
					button {
						opacity: 1;
					}
				}
			}
			figcaption {
				position: relative;
				display: table-caption;
				caption-side: top;
				font-size: 1.2rem;
				font-weight: 500;
				text-transform: uppercase;
				margin-bottom: 10px;
			}
		}
	}
	.buttonbar {
		z-index: 2;
		justify-content: center;
		position: absolute;
		left: 50%;
		width: 100%;
		transform: translateX(-50%);
		transition: all .3s ease-in-out;
		white-space: nowrap;
		overflow: hidden;
		ul {
			padding: 0 10px; // space for button
			margin-left: -30px;
			opacity: 0;
			overflow: hidden;
			border-top-right-radius: var(--but-rad);
			border-bottom-right-radius: var(--but-rad);
		}
		a {
			opacity: 0;
		}
		.circ {
			z-index: 2;
			pointer-events: none;
			min-width: 30px;
			padding: 0;
			opacity: 0;
		}
		&.active {
			ul,
			a {
				opacity: 1;
			}
			a {
				transition: all .3s ease-in-out, opacity .3s .3s ease-in-out;
			}
			.circ {
				pointer-events: none !important;
			}
		}
		&:not(.active) {
			width: 30px;
		}
	}
	&:hover:not(.sorting) {
//		background: #FFF;
		.wrap:hover {
			.buttonbar {
				z-index: 3;
				&:not(.active) {
					.circ {
						pointer-events: auto;
						opacity: 1;
					}					
				}
			}				
		}
	}
	.configbar {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(0, -50%);
		width: 30px;
		height: 100%;
		min-height: 140px;
		margin: 0;
		padding: 5px 0;
		display: flex;
//		justify-content: center;
		justify-content: flex-start;
		flex-direction: column;
		transition: .3s ease-in-out;
		background: transparent;
		border-radius: 8px 0 0 8px;
		>li {
			width: 30px;
			text-align: center;
			list-style-type: none;
			line-height: 0;
		}
		button {
			pointer-events: auto;
			padding: 0;
			svg {
				display: block;
				margin: auto;
			}
			.fill {
				fill: var(--pri-col);
			}
			&:hover {
				.fill {
					fill: var(--pri-bg-hvr);
				}
			}
		}
		.drag {
			cursor: move;
		}
	}
	#block {
		position: relative;
		margin: 0 0 0 30px;
		margin-bottom: 250px; // temp fix for settings modal overflow
		&:before { // background when sorting ??
			content: '';
			position: absolute;
			top:0;
			left: 0;
			width: 100%;
			height: 100%;
			background: var(--pan-hr);
box-shadow: 0 5px 15px -5px rgba(#000, .25);
		}
		&.sorting {
			.wrap {
				pointer-events: none;
				background: inherit;
			}
		}
		nav {
			&:first-of-type {
				top: -16px;
			}
			&:last-of-type {
				bottom: -15px;
			}
		}
		>.wrap {
			position: relative;
			list-style: none;
			padding: 0 40px;
			background: #FFF; // leave (page)
			transition: .3s ease-in-out;
			min-height: 140px;
			display: flex; // minimum ratio enforcer
			&[class*="ratio"]:before { // minimum ratio enforcer
				content: '';
				pointer-events: none;
				display: block;
				width: 100%;
				margin-left: -100%;
			}
			&:not(:first-child) {
				border-top: 1px solid var(--pan-hr);
			}
			&[style*="visibility"], // drag donor
			&[style*="position"], // drag clone
			&:hover {
				.configbar {
					transform: translate(-30px, -50%);
					background: var(--pri-bg);
				}
				.butdbl {
					opacity: 1;
					pointer-events: auto;
				}
			}
			&.sorting {
box-shadow: 0 5px 15px -5px rgba(#000, .25);
			}
		}
	}
	.ratio-fullheight::before {
		padding-top: 100vh;
	}
	.ratio-square:before {
		aspect-ratio:1/1;
	}
	.ratio-camera:before {
		aspect-ratio:3/2;
	}
	.ratio-classic:before {
		aspect-ratio:4/3;
	}
	.ratio-widescreen:before {
		aspect-ratio:16/9;
	}
	.ratio-cinema:before {
		aspect-ratio:21/9;
	}
	.settings,
	.switchtype { // modals
		position: absolute !important;
		left: 10px;
//		top: 50%;
//		transform: translateY(-50%);
		top: 10px;
		visibility: visible;
		opacity: 1;
	}
	.settings {
		min-height: auto;
		width: 100%;
		min-width: 450px;
		max-width: 560px;
		padding: 0 !important;
		.butdbl {
			position: absolute;
			left: auto;
			right: 10px;
			transform: translate(0, -50%);
			opacity: 1;
			~ input,
			~ label,
			~ span {
				margin-right: 180px; // space for buttons
			}
		}
		
		dl {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			dt {
				z-index: 1;
				cursor: pointer;
				display: flex;
				align-items: center;
				min-height: 30px;
				margin-right: 1px;
				padding: 5px 15px;
				font-size: 1rem;
				font-family: var(--lab-fam);
				font-weight: var(--lab-wgt);
				text-transform: var(--lab-uc);
				color: var(--pri-col);
				background: var(--pri-bg);
				transition: .3s ease-in-out;
				&:first-of-type {
					border-top-left-radius: var(--box-rad);
				}
				&:last-of-type {
					margin-right: 0;
					border-top-right-radius: var(--box-rad);
				}
				&:hover {
					color: var(--pri-col-hvr);
					background: var(--pri-bg-hvr);
				}
				&.on {
					color: var(--pri-col-dis);
					background: var(--pri-bg-dis);
				}
				&:not(.on) {
					+dd {
						height: 0;
						overflow: hidden;
					}
				}
			}
			dd {
				position: relative;
				order: 1;
				width: 100%;
				.fgroup {
					margin-top: -1px;
					padding-top: 20px;
					border-top: 1px solid var(--pri-bg-dis);
				}
			}
		}
	}
	.switchtype {
		display: flex;
		flex-direction: column;
		dl {
			position: relative;
			min-height: 150px;
			padding-right: 220px;
		}
		dt a,
		dd {
			transition: .3s ease-in-out;
		}
		dt {
			a {
				cursor: pointer;
				display: block;
				min-width: 180px;
				font-size: 1.2rem;
				line-height: 30px;
				color: var(--nav-col);					
			}
			&.active,
			&:hover {
				a {
					color: var(--nav-col-on);
				}
				+dd {
					opacity: 1;
				}
			}
			&.active {
				&:before { // reactivates active link on image hover
					z-index: 1;
					cursor: auto;
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					height: 100%;
					width: 220px;
				}
			}
		}
		&:hover {
			dt.active:not(:hover) {
				a {
					color: inherit;						
				}
			}
		}
		dd {
			pointer-events: none;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 200px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			opacity: 0;
			picture {
				position: relative;
				display: block;
				background: #FFF;
box-shadow: 0 0 15px -5px rgb(0 0 0 / 20%);
				width: 200px;
				overflow: hidden;
				padding-top: 150px;
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					background: #FFF; // leave
					transition: opacity 0.3s ease-in-out;
				}
			}
		}
	}
	.block {
		width: 100%;
		max-width: 900px;
		margin: auto;
		padding: 40px 0;
		background: #FFF; // default
		transition: background .3s ease-in-out;
		&.pt-off {
			padding-top: 0 !important;
		}
		&.pb-off {
			padding-bottom: 0 !important;
			&.image.carousel ol {
				bottom: 40px;
			}
		}
		&.pt-hlf {
			padding-top: 20px !important;
		}
		&.pb-hlf {
			padding-bottom: 20px !important;
			&.image.carousel ol {
				bottom: 60px;
			}
		}
		&.pt-dbl {
			padding-top: 80px !important;
		}
		&.pb-dbl {
			padding-bottom: 80px !important;
			&.image.carousel ol {
				bottom: 120px;
			}
		}
		&.fullwidth {
			width: calc(100% + 80px);
			max-width: none;
			margin: 0 -40px;
			padding: 0 !important;
		}
		&.txt-left {
			p.points,
			[contenteditable] {
				text-align: left;
			}
			picture.icon {
				margin-right: auto;
			}
		}
		&.txt-center {
			>h3,
			p.points,
			[contenteditable] {
				text-align: center;
			}
			picture.icon {
				margin-left: auto;
				margin-right: auto;
			}
		}
		&.txt-right {
			p.points,
			[contenteditable] {
				text-align: right;
			}
			picture.icon {
				margin-left: auto;
			}
		}
		.txt {
			&.txt-left {
				text-align: left;
			}
			&.txt-center {
				text-align: center;
			}
			&.txt-right {
				text-align: right;
			}
			&.txttop {
				align-self: flex-start;
			}
			&.txtmiddle {
				align-self: center;
			}
			&.txtbottom {
				align-self: flex-end;
			}
		}
		&.whitetext,
		.whitetext {
			[contenteditable] {
				color: #FFF !important;
			}
			h2, h3, p {
				color: #FFF !important;
			}
		}
		&:before {
			// bg colour
			content: '';
			pointer-events: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: inherit;
		}
		>* {
			position: relative;
		}
		.fade {
			opacity: .5;
		}
		hgroup { // text heading group
			display: flex;
			flex-direction: column;
			h3 {
				&:not(.first) {
					margin-top: -15px; //  leave 5px
				}
				&.first { // display first
					order: -1;
					margin-bottom: 5px;
				}
			}
		}
		h2,
		h3 {
			margin-bottom: 20px;
		}
		.p div {
			margin-top: 20px;
		}
		h2 {
//			color: $orange;
			font-size: 3.2rem;
//			font-weight: 500;
			font-family: var(--hdg-fam);
			font-weight: var(--hdg-wgt);
			text-transform: var(--hdg-uc);			
			color: var(--hdg-col);
		}
		h3 {
			font-size: 1.6rem;
			font-weight: var(--sub-wgt);
			color: var(--sub-col);
		}
		.p {
			font-size: 1.6rem;
		}
		[contenteditable]:focus {
			color: inherit;
		}
		figure {
			z-index: 1;
			position: relative;
			display: flex;
			flex-direction: column;
//			width: 100%;
			margin: 0;
		}
		&:not(.withtext) { // block type
			figure {
				width: 100%;
			}			
		}
		figcaption {
			z-index: 1;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: flex-end;
			padding: 0 15px 15px 15px;
			min-height: 35px; // 50 inc. padding
			font-size: 1.4rem;
			color: #FFF !important; // prevent focus recolouring
			&:before {
				z-index: -1;
				pointer-events: none;
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				min-height: 50px;
				background: linear-gradient(transparent, rgba(#000, .7));
			}
			&:empty:not(:focus):after { // default
				content: attr(data-placeholder);
				pointer-events: none;
				position: relative;
			}
		}
		picture {
			&.contain {
				img {
					object-fit: contain;
				}
			}
			&.bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			&.icon {
				display: block;
				width: 50px;
				height: 50px;
				font-size: 50px;
				margin-bottom: 10px;
				>[class^="material-symbols"] {
					font-size: inherit;
					color: var(--hdg-col);
				}
				>.diamond {
					transform: scale(1.5) translateX(2%);
				}
				>.eco {
					transform: scale(1.75) translate(2%, -6%);
				}
				>.key {
					transform: scale(1.6) translate(2%, 2%) rotate(45deg);
				}
				>.workspace_premium {
					transform: scale(1.35);
					//transform: scale(1.35) translate(-2%, -2%) rotate(-45deg);
				}
				.weight {
					transform: scale(1.8) translateY(-2%);
				}
				.forest {
					transform: scale(1.5) translateY(-3%);
				}
				>.explore,
				>.signpost,
				>.sentiment_dissatisfied,
				>.sentiment_satisfied,
				>.sentiment_neutral {
					transform: scale(1.4);
				}
				>.egg {
					transform: scale(1.65);
				}
				>.local_cafe {
					transform: scale(1.6) translateX(-1%);
				}
				>.hive {
					transform: scale(1.3);
				}
				>.search,
				>.coronavirus,
				>.rocket_launch {
					transform: scale(1.5);
				}
				.favorite {
					transform: scale(1.6) translateY(3%);
				}					
			}
		}
		.field picture.icon {
			position: absolute;
			top: 50%;
			right: 14px;
			transform: translateY(-50%);
			width: 30px;
			height: 30px;
			font-size: 30px;
		}
		.ratio:before {
			content: '';
			display: block;
			width: 100%;
		}
		.ratio-auto {
			&:before {
				position: absolute !important;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			img {
				position: relative;
				display: block;
				height: auto;
			}
		}
		.butdbl {
			z-index: 2;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;
			pointer-events: none;
		}
		&.image { // block type
			picture {
				display: block;
				width: 100%;
				margin: 0;
				&:empty {					
					background: $lightgrey url(/img/icon-photo.svg) center no-repeat;
					background-size: 30px 30px;
				}
				&:before { // hover tint
					pointer-events: none;
					content: '';
					z-index: 1;
					position: relative;
					opacity: 0;
					background: rgba(#FFF, .5);
					transition: opacity .3s ease-in-out;
				}
			}
			&:hover {
				picture:before {
					opacity: 1;
				}
			}
			&.single { // block type
				picture {
					&:not(.ratio) { // natural
						img {
							width: 100%;
							height: auto;
						}				
					}
				}
			}
			&.carousel { // block type
				&.fullwidth {
					ol {
						bottom: 40px;
					}
				}
				figure {
					z-index: 1;
					pointer-events: none;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					opacity: 0;
					transition: opacity .3s .3s ease-in-out;
					&.active {
						z-index: 1;
						pointer-events: auto;
						transition-delay: 0s;
						opacity: 1;
					}
				}
				picture,
				picture img {
					height: 100%;
				}
				picture {
					&:before {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
				ol {
					z-index: 2;
					position: absolute;
					bottom: 80px;
					left: 50%;
					transform: translateX(-50%);
					display: flex;
					li {
						margin: 0 5px;
						list-style-type: none;
					}
					button {
						display: block;
						width: 20px;
						height: 20px;
						text-indent: 20px;
						white-space: nowrap;
						overflow: hidden;
						border-radius: 50%;
						border: 2px solid #FFF;
						transition: background .3s ease-in-out;
						background: none;
						&:hover,
						&.active {
							cursor: pointer;
							background: #FFF;
						}
					}
					&.off {
						pointer-events: none;
						button {
							border-color: $disabled;
							&:hover {
								background: $disabled;
							}
							&.active {
								background: $disabled;
							}
						}
					}
				}
			}
			&.withtext { // block type
				display: flex;
				justify-content: space-between;
				padding: 40px 0;
				>.txt,
				>figure {
					width: calc(50% - 20px);
					transition: transform .3s ease-in-out;
				}
				&.cover {
					padding: 0;
					align-items: normal !important;
					figure {
						margin: 0 !important;;
					}
					.txt {
						padding: 40px 0;
					}
				}
				&.txttop {
					align-items: flex-start;
					figure {
						margin-bottom: auto;
						img {
							object-position: top;
						}
					}
				}
				&.txtmiddle {
					align-items: center;
					figure {
						img {
							object-position: center;
						}
					}
				}
				&.txtbottom {
					align-items: flex-end;
					figure {
						margin-top: auto;
						img {
							object-position: bottom;
						}
					}
				}
				figure {
					position: relative;
					//margin: 40px 0;
					&:before { // ratio
						content: '';
						display: block;
						width: 100%;
					}
					&.right {
						transform: translateX(100%) translateX(40px);
						+.txt {
							transform: translateX(-100%) translateX(-40px);
						}
					}
					&:not(.ratio-auto) {
						picture {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}
					}
				}
				picture {
					&:before {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;						
					}
				}
			}
		}
		&.text { // block type
			
		}
		&.list { // block type
			position: initial;
			&.numbered,
			&.bulleted {
				display: flex;
				>ul, >ol {
					counter-reset: li;
					overflow: hidden;
					display: inline-flex;
					flex-direction: column;
					margin-left: -26px; // offset space for drag
					padding-left: 26px; // space for drag
					li {
						position: relative;
						display: flex;
						list-style-type: none;
						margin: 10px 0;
					}
				}
				&.txt-left {
					justify-content: flex-start;
				}
				&.txt-center {
					justify-content: center;
				}
				&.txt-right {
					justify-content: flex-end;
				}
			}
			&.whitetext {
				li {
					&.ulid,
					&.olid {
						&:before {
							color: var(--pri-bg);
							background: #FFF; // leave
						}
						.fill {
							fill: #FFF; // leave
						}
					}
				}
			}
		}
		&.stream {
			&.video {
				video {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					opacity: 0;
					transition: opacity .3s ease-in-out;
					&[src] {
						opacity: 1;
					}
				}
				figure {
					background: $lightgrey url(/img/icon-video.svg) center no-repeat;
					background-size: 30px 30px;
				}
				figure {
					&:before {
						pointer-events: none;
						z-index: 1;
						opacity: 0;
						background: rgba(255, 255, 255, 0.5);
						transition: opacity 0.3s ease-in-out;
					}
					&:hover {
						&:before {
							opacity: 1;
						}
					}
				}
				figcaption {
					bottom: auto;
					top: 0;
					padding: 15px 15px 0 15px;
					&:before {
						top: 0;
						bottom: auto;
						background: linear-gradient(rgba(0, 0, 0, 0.7), transparent);
					}
				}
			}
			&.audio {
				padding: 80px 0;
				text-align: center;
				background-image: url(/img/icon-audio.svg);
				background-position: center;
				background-repeat: no-repeat;
				background-size: 30px 30px;
				audio {
					position: relative;
					text-align: center;
					opacity: 0;
					//max-width: 300px;
					//height: 60px;
					padding: 0;
					border-radius: 30px;
					transition: opacity .3s ease-in-out;
					&[src] {
						opacity: 1;
					}
				}
				.butdbl {
//					margin-top: 60px;
				}
			}
		}
		&.divider {
			&.numbered {
				>div {
					position: relative;
					&:before {
						content: '';
						position: absolute;
						top: 50%;
						left: 0;
						width: 100%;
						height: 1px;
						background: var(--pan-hr);
					}
					[contenteditable] {
						z-index: 1;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
						display: flex;
						align-items: center;
						justify-content: center;
						width: 50px;
						height: 50px;
						margin: auto;
						font-size: 2rem;
						font-weight: var(--hdg-wgt);
						border-radius: var(--hr-rad);
						color: var(--li-mkr-col);
						background: var(--li-mkr-bg);
//box-shadow: 0 4px 8px 0px rgba(#000, .2);
						&:empty:before {
							content: '0';
						}
	/*					&:focus {
							background: $darkgrey;
							&:before {
								display: none;
							}
						}*/
					}
				}
			}
		}		
		&.task {
			h2 {
				font-size: 2.4rem;
			}
			h3:not(.first) {
				margin-top: -10px;
			}
			p.points {
				margin-top: -10px;
				font-size: 1.4rem;
			}
			&.radio,
			&.checkbox {
				display: flex;
				flex-direction: column;
				&.wide {
					ul, ol {
						width: 100%;
					}
				}
				>h2 {
					&:empty:before {
						content: 'Enter Question...';
					}
				}
				>h3 {
					font-size: 1.8rem;
					&:empty:before {
						content: 'Enter Help Text...';
					}
				}
				ul {
					display: inline-flex;
					flex-direction: column;
					margin: auto; // default centered
					counter-reset: li;
				}
				&.txt-left {
					h2,
					h3 {
						text-align: left;
					}
					ul {
						margin-right: auto;
						margin-left: 0;
					}
					.feedback >div {
						justify-content: flex-start;
					}
				}
				&.txt-center {
					h2,
					h3 {
						text-align: center;
					}
					ul {
						margin-left: auto;
						margin-right: auto;
					}
					.feedback >div {
						justify-content: center;
						div[contenteditable] {
							text-align: center;
						}
						span {
							display: flex;
							justify-content: center;
							width: 100%;
							margin: 0 0 10px 0;
						}
					}
				}
				&.txt-right {
					h2,
					h3 {
						text-align: right;
					}
					ul {
						margin-left: auto;
						margin-right: 0;
					}
					.feedback >div {
						justify-content: flex-end;
					}
				}
			}
			&.radio {
				label {
					border-radius: 50%;
				}
			}
			&.checkbox {
				label {
					border-radius: 2px;
				}
			}
			&.freeform {
				display: flex;
				flex-direction: column;
				p {
					&:not(.points) {
						font-size: 1.8rem;
						margin: 0 auto 20px auto;
						text-align: center;						
					}
				}
				.p {
					width: 100%;
					padding: 15px;
					border-radius: 8px;
					border: 1px solid var(--task-col);
					color: var(--task-col);
					text-align: left !important;
					div {
						&:first-child {
							margin-top: 0 !important;
						}
					}
				}
				small {
					margin-top: -20px;
					padding: 0 10px;
					font-size: 1rem;
					text-transform: uppercase;
					font-weight: 500;
					line-height: 2rem;
				}
				/*strong {
					padding: 0 10px 10px 10px;
					font-size: 1.8rem;
					font-weight: 400;
					text-align: center;
				}*/
				&.multiline {
					.p {
						min-height: 200px;
						padding-bottom: 20px;
					}
				}
			}
		}		
		.feedback {
			z-index: 120; // above tint
			position: absolute !important;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: calc(100vw - 330px);
			max-width: 600px;
			padding: 40px;
			border-radius: 8px;
			background: #FFF; // leave (page bg)
			box-shadow: 0 5px 15px -5px rgba(#000, .25);
			>div {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}
			span {
				display: block;
				transform: translateY(6px);
				margin-right: 10px;
				.fill {
					fill: var(--hdg-col);
				}
			}
			h2[contenteditable] {
				font-weight: 400;
				&:empty:before {
					content: 'Enter Heading...';
				}
			}
			div[contenteditable] {
				width: 100%;
				margin-bottom: 40px;
				text-align: center;
				>div {
					margin-top: 20px;
				}
				&:empty:before {
					content: 'Enter Explanation...';
				}
			}
			&.active {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}

// task block (radio/checkbox)
li.checkboxid { // root style so drag state maintains style
	position: relative;
	margin: 10px 0;
	list-style-type: none;
	.but.icon {
		border: 0 !important;
	}
	div {
		&.answer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 10px;
			border-radius: 8px;
			color: var(--task-col);
			border: 1px solid var(--task-col);
			background: var(--task-bg);
			transition: .3s ease-in-out;
			label {
				cursor: pointer;
				position: relative;
				width: 20px;
				min-width: 20px; // prevent squish
				height: 20px;
				text-indent: 20px;
				white-space: nowrap;
				margin	: 0 10px 0 0;
				border: 1px solid var(--task-col);
				overflow: hidden;
				transition: border .3s ease-in-out;
				input {
					position: absolute;
					bottom: 100%;
					right: 100%;
				}
				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					opacity: 0;
					.fill {
						fill: var(--task-col);
					}
				}
			}
			[contenteditable] {
				margin-right: auto;
				&:empty:before {
					content: 'Enter Answer...';
					opacity: 1;
				}
			}
			&.correct label svg:last-of-type,
			&:not(.correct) label svg:first-of-type {
				opacity: 1;
			}
			.but {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				height: 20px;
				width: 20px;
				min-width: 20px;
				margin-left: 8px;
				.fill {
					fill: var(--task-col);
				}
			}
			&.correct {
				color: var(--task-col-sel);
				border-color: var(--task-bg-sel);
				background: var(--task-bg-sel);
				label,
				.but {
					border-color: var(--task-col-sel);
					.fill {
						fill: var(--task-col-sel);
					}
				}
				[contenteditable] {
					&:empty:before {
						color: rgba(#FFF, .5); // (leave page bg)
					}
				}
				&:hover {
					color: var(--task-col-sel-hvr);
					border-color: var(--task-col-sel-hvr);
					background: var(--task-bg-sel-hvr);
					label,
					.but {
						border-color: var(--task-col-sel-hvr);
						.fill {
							fill: var(--task-col-sel-hvr);
						}
					}
				}
			}
			&.correct {
				.fade {
					color: rgba(#FFF, .5) !important;; // (leave page bg)
				}
			}
			&:hover {
				color: var(--task-col-hvr);
				border-color: var(--task-col-hvr);
				background: var(--task-bg-hvr);
				label {
					border-color: var(--task-col-hvr);
					.fill {
						fill: var(--task-col-hvr);
					}
				}
				.but {
					.fill {
						fill: var(--task-col-hvr);
					}
				}
			}
		}
	}
	svg {
		display: block;
		transition-duration: 0s;
	}
	&:before,
	&:after { // extend hover area over drag
		content: '';
		position: absolute;
		top: 0;
		height: 100%;
	}
	&:before {
		right: 100%;
		width: 30px;
	}
	&:after {
		left: 100%;
		width: 56px;
	}
	>.but {
		z-index: 1;
		position: absolute;
		top: 50%;
		opacity: 0;
		&.add {
			min-height: auto;
			.fill {
				fill: #FFF !important;
			}
		}
	}
	.drag {
		left: -10px;
		transform: translate(-100%, -50%);
		cursor: move;
	}
	.bin {
		left: 100%;
		transform: translate(40px, -50%);
		&:disabled {
			cursor: not-allowed;
			.fill {
				fill: var(--pri-col-dis) !important;
			}
		}
	}
	.add {
		left: 100%;
		transform: translate(10px, -50%);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: var(--pri-bg);
		&:hover {
			background: var(--pri-bg-hvr);
		}
	}
	.bin,
	.drag {
		.fill {
			fill: var(--pri-bg);
		}
		&:hover {
			.fill {
				fill: var(--pri-bg-hvr);
			}
		}
	}
	&.sorting,
	&:hover {
		>.add,
		>.bin,
		>.drag {
			opacity: 1;
		}
	}
}
// list blocks
li { // root styles so drag state maintains style
	&.ulid,
	&.olid {
		position: relative;
		list-style-type: none;
		display: flex;
		&:after { // extend hover area
			content: '';
			position: absolute;
			top: 0;
			right: 100%;
			width: 30px;
			height: 20px;
		}
		.drag {
			z-index: 1;
			cursor: move;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(-100%, -50%);
			min-height: 20px; // override
			width: 26px;
			margin-top: -2px;
			opacity: 0;
		}
		svg {
			display: block;
		}
		.fill {
			fill: var(--pri-bg);
		}
		&.sorting,
		&:hover {
			>.drag {
				opacity: 1;
			}
		}
		span {
			min-width: 40px;
		}
	}
	&.ulid { // bulletted list
		display: flex;
		&:before { // bullet marker
			content: '';
			align-self: flex-start;
			transform: translateY(3px);
			margin-right: 10px;
			width: 12px;
			min-width: 12px;
			height: 12px;
			border-radius: 50%; // leave
			background: var(--li-mkr-bg);
		}
		.drag {
			margin-top: -2px;
		}
	}
	&.olid { // numbered list
		&:before { // number marker
			counter-increment: li;
			content: counter(li);
			align-self: flex-start;
			transform: translateY(-3px);
			margin-right: 10px;
			width: 24px;
			min-width: 24px;
			line-height: 24px;
			text-align: center;
			font-size: 1.2rem;
			font-weight: 500;
			border-radius: var(--li-rad);
			color: var(--li-mkr-col);
			background: var(--li-mkr-bg);
		}
		.drag {
			margin-top: -5px;
		}
	}
}

</style>
