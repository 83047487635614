<template lang="pug">
include ../pug/svg
FieldColour(
	:class="class"
	label="Background Colour"
	v-model:colour="modelBgColor",
	@fieldUpdated="fieldUpdated")
FieldBoolean(
	:class="class"
	v-model="modelWhiteText"
	label="Use White Text"
	@fieldUpdated="fieldUpdated")
</template>

<script>
import FieldBoolean from '../components/FieldBoolean';
import FieldColour from '../components/FieldColour';

export default {
	name: 'FieldBackgroundColour',
	props: {
		class: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: 'Background Colour',
		},
		bgColor: String,
		whiteText: {
			type: Boolean,
			default: false
		},		
	},
	emits: ['fieldUpdated','update:bgColor','update:whiteText'],
	components: {
		FieldBoolean,
		FieldColour,
	},
	computed: {
		modelBgColor: {
			get() {
				return this.bgColor;
			},
			set(value) {
				this.$emit('update:bgColor', value);
			}
		},
		modelWhiteText: {
			get() {
				return this.whiteText;
			},
			set(value) {
				this.$emit('update:whiteText', value);
			}
		},
	},
	methods: {
		fieldUpdated() {
			this.$emit('fieldUpdated');
		},
	},
}
</script>

<style lang="scss">
</style>
