<template lang="pug">
include ../pug/svg
div.field(:class="class")
	select(v-model="modelName" @change="fieldUpdated")
		optgroup(label="Icons")
			option(:value="null") None
			option(value="search") Search
			option(value="favorite") Heart
			option(value="key") Key
			option(value="workspace_premium") Certificate
			option(value="rocket_launch") Rocket
			option(value="sentiment_satisfied") Happy face
			option(value="sentiment_neutral") Neurtal face
			option(value="sentiment_dissatisfied") Sad face
			option(value="diamond") Diamond
			option(value="eco") Leaf
			option(value="hive") Honeycombe
			option(value="egg") Egg
			option(value="explore") Compass
			option(value="signpost") Signpost
			option(value="forest") Trees
			option(value="weight") Weight
			option(value="local_cafe") Coffee
			option(value="recycle") Recycle
			option(value="cookie") Cookie
			//success
			option(value="handshake") Handshake
			option(value="lightbulb") Lightbulb
			option(value="tips_and_updates") Tip
			option(value="electric_bolt") Lightning
			option(value="auto_awesome") Stars
			option(value="verified") Verified
			option(value="insights") Insights
			option(value="speaker_notes") Notes
			//science
			option(value="coronavirus") Virus
			option(value="microbiology") Microbe
			option(value="biotech") Microscope
			option(value="genetics") DNA
			option(value="science") Science
			option(value="headphones") Headphones
			option(value="egg_alt") Fried Egg
			option(value="credit_card") Credit Card
			option(value="savings") Savings
			option(value="sell") Tag
			option(value="shopping_bag") Bag
			option(value="memory") Chip
			option(value="psychology") Psychology
			//-option(value="emoji_objects") Emoji Objects
			option(value="sunny") Sun
			option(value="thunderstorm") Thunderstorm
			option(value="rainy") Rainy
			option(value="train") Train
			option(value="subway") Tube
			option(value="tram") Tram
			option(value="directions_bus") Bus
			option(value="directions_car") Car
			option(value="directions_boat") Ferry
			option(value="directions_bike") Bike
			option(value="route") Route
			//
			option(value="auto_detect_voice") Microphone
			option(value="campaign") Campaign
			option(value="star") Star
			option(value="auto_fix_high") Magic
			option(value="rate_review") Feedback
			option(value="thumb_up") Thumb Up
			option(value="thumb_down") Thumb Down
			option(value="fingerprint") Fingerprint
			//-option(value="directions_walk") Walk
			//-option(value="directions_run") Run
			//-option(value="sprint") Sprint
	label Icon / Symbol
	+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
	picture.icon(v-if="name")
		span.material-symbols-rounded(:class="name") {{name}}
div.field(:class="class")
	select(v-model="modelColour" @change="fieldUpdated")
		option(:value="null") Default
		option(:value="customBgColour") Custom
		option(v-for="colour in cssPallet" :value="colour.hex") {{colour.name}}
	input.hex(type="text" maxlength="6" placeholder="None" v-model="modelColour" @keyup="fieldUpdated")
	button.colour(type="button" disabled :style="{'background-color' : hex}")
	label Icon / Colour
	+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
</template>

<script>
export default {
	name: 'FieldIcon',
	props: {
		class: {
			type: String,
			default: '',
		},
		name: String,
		colour: String,
	},
	emits: ['fieldUpdated','update:name','update:colour'],
	computed: {
		modelName: {
			get() {
				return this.name;
			},
			set(value) {
				this.$emit('update:name', value);
			}
		},
		modelColour: {
			get() {
				return this.colour;
			},
			set(value) {
				this.$emit('update:colour', value);
			}
		},
		cssPallet() {
			return this.$store.state.gui.cssPallet;
		},
		customBgColour() {
			return this.customColour(this.colour);
		},
		hex() {
			// check for valid hex values (e.g(#FFF / #FFFFFF)
			const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
			const valid = hexRegex.test('#' + this.colour);
			
			return valid ? '#' + this.colour : 'transparent';
		},
	},
	methods: {
		customColour(colour) {
			let custom = true;
			
			if (colour) {
				for(let key of Object.keys(this.cssPallet)) {
					if (this.cssPallet[key].hex === colour) {
						custom = false;
					}
				}
			} else {
				custom = false;
			}
			
			return (!custom) ? 'ignore' : colour;
		},
		fieldUpdated() {
			this.$emit('fieldUpdated');
		},
	},
}
</script>

<style lang="scss">
</style>
