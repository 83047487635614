<template lang="pug">
include ../pug/svg
div.block(:class="blockClasses" @mouseleave="focus=null" :style="blockStyles")
	contenteditable.question(tag="h2" v-model="block.content.question" :noNL="true" :spellcheck="(focus===1)" :class="{fade:focus&&focus!==1}" @focus="focus=1" @blur="focus=null"  @keyup="blockUpdated")
	h3(v-if="settings.minWords&&settings.maxWords") Please write between {{settings.minWords}} and {{settings.maxWords}} words.
	h3(v-else-if="settings.minWords") Please write a minimum of {{settings.minWords}} words.
	h3(v-else-if="settings.maxWords") Please write a maximum of {{settings.minWords}} words.
	p.points(v-if="course.examMode&&course.examShowAllocation")
		template(v-if="settings.examPoints > 1") {{terminology('course','point','singular')}} range [ 0 - {{settings.examPoints}} {{terminology('course','point','plural')}} ]
		template(v-else) {{terminology('course','point','singular')}} range [ 0 - {{settings.examPoints}} {{terminology('course','point','singular')}} ]
	contenteditable.p(tag="div" :spellcheck="(focus===2)" :class="{fade:focus&&focus!==2}" v-model="answer" :noHTML="!multiline" :noNL="!multiline" @focus="focus=2" @blur="focus=null")
	small(v-if="settings.minWords||settings.maxWords") Words: {{wordCount}}
	div.modal.settings(v-if="settings.active")
		ModalBar(label="Block Settings" :useDrag="true" @close="toggleSettings()")
		form.body
			fieldset.fgroup
				FieldText.c2(name="minWords" label="Word Limit / Minimum" v-model.number="block.settings.minWords" :required="false" append="Words" :numeric="true" :maxlength="4"  @keyup="blockUpdated" @blur="blockUpdated")
				FieldText.c2(name="maxWords" label="Word Limit / Maximum" v-model.number="block.settings.maxWords" :required="false" append="Words" :numeric="true" :maxlength="4"  @keyup="blockUpdated" @blur="blockUpdated")
				div.field.c2
					select(v-model="block.settings.alignHorz" @change="blockUpdated")
						option(:value="nullValue") Default
						option(value="txt-left") Left Aligned
						option(value="txt-center") Centered
						option(value="txt-right") Right Aligned
					label Text Alignment
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				FieldText.c2(v-if="course.examMode" name="examPoints" :label="'Exam '+terminology('course','point','plural')" v-model.number="block.settings.examPoints" :required="true" :numeric="true" :maxlength="3" :append="terminologyNumeric(block.settings.examPoints,'course','point','plural')" @keyup="blockUpdated")
			div.foot
				span.req Required
				button.but.pri(type="button" @click="toggleSettings()") Apply
				button.but.sec(type="button" @click="resetSettings()") Reset
</template>

<script>
import contenteditable from 'vue-contenteditable';
import FieldBoolean from './FieldBoolean';
import FieldText from './FieldText';
import ModalBar from './ModalBar';

export default {
	name: 'BlockTaskFreeform',
	props:['course', 'block'],
	emits: ['blockUpdated', 'toggleSettings', 'resetSettings'],
	components: {
		contenteditable,
		ModalBar,
		FieldBoolean,
		FieldText,
	},
	data() {
		return {
			focus: null,
			answer: '', // don't bind to block.content.answer ()
			nullValue: null, // fix
		}
	},
	computed: {
		settings() {
			return this.block.settings;
		},
		multiline() {
			return (this.settings.minWords || this.settings.maxWords);
		},
		blockStyles() {
			let styles = [];
			
			return styles.join(';');
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];
			
			if (this.settings.alignHorz) {
				classes.push(this.settings.alignHorz);
			}
			
			if (this.multiline) {
				classes.push('multiline');
			}
			
			return classes.join(' ');
		},
		wordCount() {
			let str = this.answer;
			
			str = str.replace(/&nbsp;/g, ' ');
			str = str.replace(/(<([^>]+)>)/gi, ' ');
			str = str.replace(/\n/g, ' ');
			str = str.split(' ').filter(s => s !== '');
			
			return str.length;
		},
	},
	methods: {
		terminologyNumeric(number, groupKey, termKey, lowercase) {
			const varKey = (number == 1) ? 'singular' : 'plural';
			return this.terminology(groupKey, termKey, varKey, lowercase);
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		blockUpdated() {
			this.$emit('blockUpdated');
		},
		toggleSettings() {
			this.$emit('toggleSettings', this.block);
		},
		resetSettings() {
			this.$emit('resetSettings', this.block);
		},
	},
}
</script>

<style lang="scss">
</style>
