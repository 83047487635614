<template lang="pug">
include ../pug/svg
//-pre block: {{block}}
div.block(:class="blockClasses" @mouseleave="focus=null" :style="blockStyles")
	figure(:class="figureClasses")
		picture(:class="pictureClasses")
			img(v-if="imageSrc" :src="imageSrc" :alt="imageAlt" @load="imageChanged")
		contenteditable(v-if="block.settings.figcaption&&imageSrc" tag="figcaption" :spellcheck="(focus===4)" :class="{fade:focus&&focus!==4}" v-model="image.figcaption" :noNL="true" @focus="focus=4" @blur="focus=null" @keyup="blockUpdated" :data-placeholder="placeholder")
		div.butdbl.text(:class="{unselected : !imageSrc}")
			button.but(type="button" @click="selectMedia()") Media
			button.but(type="button" @click="removeMedia()")
				span Remove Media
				+svg(svg-filename="iconBin")
			button.but(type="button" @click="embedMedia()") Embed
	div.txt(:class="[settings.alignHorz, settings.alignVert, {whitetext:settings.whiteText}]")
		hgroup(v-if="settings.useHeader||settings.useSubheader")
			contenteditable(v-if="settings.useHeader" tag="h2" :spellcheck="(focus===1)" :class="{fade:focus&&focus!==1}" :contenteditable="editable" v-model="block.content.headed" :noNL="true" @focus="focus=1" @blur="focus=null" @keyup="blockUpdated")
			contenteditable(v-if="settings.useSubheader" tag="h3" :spellcheck="(focus===2)" :class="{fade:focus&&focus!==2, first:block.settings.subheadedFirst}" :contenteditable="editable" v-model="block.content.subheaded" :noNL="true" @focus="focus=2" @blur="focus=null" @keyup="blockUpdated")
		contenteditable.p(tag="div" :spellcheck="(focus===3)" :class="{fade:focus&&focus!==3}" :contenteditable="editable" v-model="block.content.onecolumn" :noNL="false" @focus="focus=3" @blur="focus=null" @keyup="blockUpdated" :noHTML="false")
	div.modal.settings(v-if="block.settings.active")
		ModalBar(label="Block Settings" :useDrag="true" @close="toggleSettings()")
		dl.body
			dt(:class="{on:isTab(0)}" @click="setTab(0)") Text
			dd
				fieldset.fgroup
					FieldAlign(
						class="c2"
						v-model:alignHorz="settings.alignHorz"
						v-model:alignVert="settings.alignVert"
						@fieldUpdated="blockUpdated")
					FieldBoolean.c2(
						v-model="settings.useHeader"
						label="Display Heading"
						@fieldUpdated="blockUpdated")
					FieldBoolean.c2(
						v-model="settings.useSubheader"
						label="Display Subheading"
						@fieldUpdated="blockUpdated")
					FieldBoolean.c2(
						v-model="settings.subheadedFirst"
						label="Display Subheading First"
						:disabled="!settings.useSubheader"
						@fieldUpdated="blockUpdated")
			dt(:class="{on:isTab(1)}" @click="setTab(1)") {{terminology('media','media','singular')}}
			dd
				fieldset.fgroup
					div.field.c2
						select(v-model="block.settings.imageLocation" @change="blockUpdated")
							option(value="left") Left
							option(value="right") Right
						label Image Location
						+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
					FieldBoolean.c2(
						v-model="block.settings.figcaption"
						label="Use Caption"
						@fieldUpdated="blockUpdated")
					FieldRatio.c2(
						v-model:ratio="block.settings.ratio"
						label="Image Ratio"
						@fieldUpdated="blockUpdated")
					FieldBoolean.c2(
						v-model="block.settings.cover"
						label="Cover Block"
						@fieldUpdated="blockUpdated")
					FieldBoolean.c2(
						v-model="block.settings.contain"
						label="Contain Media"
						@fieldUpdated="blockUpdated")
					FieldPadding(
						class="c2"
						v-model:padTop="block.settings.padTop"
						v-model:padBottom="block.settings.padBottom" 
						@fieldUpdated="blockUpdated")
			dt(:class="{on:isTab(2)}" @click="setTab(2)") Background
			dd
				fieldset.fgroup
					FieldBackgroundColour(
						class="c2"
						v-model:bgColor="block.settings.bgColor"
						v-model:whiteText="block.settings.whiteText" 
						@fieldUpdated="blockUpdated")
			//-form.body
				fieldset.fgroup
					div.field.c2
						select(v-model="block.settings.imageLocation" @change="blockUpdated")
							option(value="left") Left
							option(value="right") Right
						label Image Location
						+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
					FieldBoolean.c2(
						v-model="block.settings.figcaption"
						label="Use Caption"
						@fieldUpdated="blockUpdated")
					FieldRatio.c2(
						v-model:ratio="block.settings.ratio"
						label="Image Ratio"
						@fieldUpdated="blockUpdated")
					FieldBoolean.c2(
						v-model="block.settings.cover"
						label="Cover Block"
						@fieldUpdated="blockUpdated")
					FieldBackgroundColour(
						class="c2"
						v-model:bgColor="block.settings.bgColor"
						v-model:whiteText="block.settings.whiteText" 
						@fieldUpdated="blockUpdated")
					FieldAlign(
						class="c2"
						v-model:alignHorz="block.settings.alignHorz"
						v-model:alignVert="block.settings.alignVert"
						@fieldUpdated="blockUpdated")
					FieldBoolean.c2(
						v-model="block.settings.subheadedFirst"
						label="Display Subheading First"
						@fieldUpdated="blockUpdated")
		div.foot
			span.req Required
			button.but.pri(type="button" @click="toggleSettings()") Apply
			button.but.sec(type="button" @click="resetSettings()") Reset
</template>

<script>
import contenteditable from 'vue-contenteditable';
import FieldBackgroundColour from '../components/FieldBackgroundColour';
import FieldBoolean from '../components/FieldBoolean';
import FieldPadding from '../components/FieldPadding';
import FieldRatio from '../components/FieldRatio';
import FieldAlign from '../components/FieldAlign';
import ModalBar from '../components/ModalBar';

export default {
	name: 'BlockImageWithText',
	props:['block'],
	emits: ['blockUpdated', 'toggleSettings', 'resetSettings', 'showMediaModal', 'showEmbedModal', 'removeMedia'],
	components: {
		contenteditable,
		ModalBar,
		FieldBackgroundColour,
		FieldBoolean,
		FieldPadding,
		FieldRatio,
		FieldAlign,
	},
	data() {
		return {
			focus: null,
			// text
			editable: true,
			// image
			showMediaModal: false,
			mediaType: 'video',
			placeholder: 'Caption placeholder',
			tabKey: 0,
		}
	},
	computed: {
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
				this.settings.alignVert ? this.settings.alignVert : 'txttop',
			];
			
			if (this.block.settings.cover) {
				classes.push('cover');	
			}
			
			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}
			
			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}
			
			return classes.join(' ');
		},
		blockStyles() {
			let styles = [];
			
			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}
			
			return styles.join(';');
		},
		figureClasses() {
			let classes = [
				this.block.settings.ratio,
				this.block.settings.imageLocation,
			];
			
			return classes.join(' ');			
		},
		pictureClasses() {
			let classes = [
				//this.block.settings.ratio,
				'posx' + this.image.positionX,
				'posy' + this.image.positionY,
			];
			
			if (this.settings.contain) {
				classes.push('contain');
			}
			
			return classes.join(' ');
		},
		image() {
			return this.block.content.images[0];
		},
		imageSrc() {
			if (this.image.media) {
				return this.image.media.mediaUrl;
			} else if (this.image.embed) {
				return this.image.embed.mediaUrl;
			}
			
			return null;
		},
		imageAlt() {
			if (this.image.media) {
				return this.image.media.seoAlt;
			} else if (this.image.embed) {
				return this.image.embed.seoAlt;
			}
			
			return null;
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		setTab(key) {
			this.tabKey = key;
		},
		isTab(key) {
			return (key === this.tabKey);
		},
		blockUpdated() {
			this.$emit('blockUpdated');
		},
		toggleSettings() {
			this.$emit('toggleSettings', this.block);
		},
		resetSettings() {
			this.$emit('resetSettings', this.block);
		},
		selectMedia() {
			// pass media type and block for insertion
			this.$emit('showMediaModal', {
				mediaType: this.mediaType,
				block: this.block,
				insertIndex: 0,
			});
		},
		embedMedia() {
			// pass media type and block for insertion
			this.$emit('showEmbedModal', {
				mediaType: this.mediaType,
				block: this.block,
				insertIndex: 0,
			});
		},
		removeMedia() {
			this.$emit('removeMedia', {
				block: this.block,
				index: 0,
			});
		},
		imageChanged() {
			if (!this.image.figcaption && this.image.media) {
				// update caption
				this.image.figcaption = this.image.media.seoDescription;
			}
		},
	},
}
</script>

<style lang="scss">
</style>
