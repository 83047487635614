<template lang="pug">
include ../pug/svg
div.block(:class="[block.type, block.subtype]" @mouseleave="focus=null")
	contenteditable.one(tag="div" :spellcheck="(focus===1)" :class="{fade:focus&&focus!==1}" v-model="block.content.onecolumn" :noNL="false" @focus="focus=1" @blur="focus=null" @keyup="blockUpdated")
	contenteditable.two(tag="div" :spellcheck="(focus===2)" :class="{fade:focus&&focus!==2}" v-model="block.content.twocolumn" :noNL="false" @focus="focus=2" @blur="focus=null" @keyup="blockUpdated")
	div.modal.settings(v-if="block.settings.active" @mouseleave="toggleSettings()")
		ModalBar(label="Block Settings" :useDrag="true" @close="toggleSettings()")
		pre {{block.settings}}
</template>

<script>
import contenteditable from 'vue-contenteditable';
import ModalBar from '../components/ModalBar';

export default {
	name: 'BlockTextTwoColumn',
	props:['block'],
	emits: ['blockUpdated', 'toggleSettings'],
	components: {
		contenteditable,
		ModalBar,
	},
	data() {
		return {
			focus: null,
		}
	},
	methods: {
		blockUpdated() {
			this.$emit('blockUpdated');
		},
		toggleSettings() {
			this.$emit('toggleSettings', this.block);
		},
	},
}
</script>

<style lang="scss">
.twocolumn {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	>div {
		width: calc(50% - 40px);
		min-width: 300px;
		flex-grow: 1;
		margin-bottom: 20px;
	}
	.one {
		margin-right: 40px;
		>div {
			margin-top: 20px;
		}
	}
	.two {

	}
}
</style>
