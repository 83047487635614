<template lang="pug">
include ../pug/svg
div.block(:class="blockClasses" @mouseleave="focus=false" :style="blockStyles")
	div
		contenteditable(tag="div" :spellcheck="false" v-model="block.content.label" :noNL="true" @focus="focus=true" @blur="focus=false" @keyup="blockUpdated")
	//-div.modal.settings(v-if="block.settings.active" @mouseleave="toggleSettings()")
		ModalBar(label="Block Settings" :useDrag="true" @close="toggleSettings()")
		form
			fieldset.fgroup
				div.field.c2
					select(v-model="block.settings.alignHorz" @change="blockUpdated")
						option(:value="nullValue") Default
						option(value="txt-left") Left Aligned
						option(value="txt-center") Centered
						option(value="txt-right") Right Aligned
					label Number Alignment
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field.c2
					input.hex(type="text" maxlength="6" placeholder="None" v-model="block.settings.bgColor" @keyup="blockUpdated")
					button.colour(type="button" disabled :style="{'background-color' : bgColour()}")
					label Background Colour
					span.on(v-if="block.settings.bgColor") Custom
					span.off(v-else) Default
			div.foot
				span.req Required
				button.but.sec(type="button" @click="resetSettings()") Use Defaults
	//-pre {{block.settings}}
</template>

<script>
import contenteditable from 'vue-contenteditable';
import ModalBar from '../components/ModalBar';

export default {
	name: 'BlockDividerNumbered',
	props:['block'],
	emits: ['blockUpdated', 'toggleSettings', 'resetSettings'],
	components: {
		contenteditable,
		ModalBar,
	},
	data() {
		return {
			focus: false,
			nullValue: null,
		}
	},
	computed: {
		settings() {
			return this.block.settings;
		},
		blockStyles() {
			let styles = [];

			return styles.join(';');
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];

			if (this.settings.alignHorz) {
				classes.push(this.settings.alignHorz);
			}

			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}

			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}

			return classes.join(' ');
		},
	},
	methods: {
		blockUpdated() {
			this.$emit('blockUpdated');
		},
		toggleSettings() {
			this.$emit('toggleSettings', this.block);
		},
		resetSettings() {
			this.$emit('resetSettings', this.block);
		},
		bgColour() {
			// check for valid hex values (e.g(#FFF / #FFFFFF)
			const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
			const valid = hexRegex.test('#'+this.block.settings.bgColor);

			return valid ? '#' + this.block.settings.bgColor : '#FFFFFF';
		},
	},
}
</script>

<style lang="scss">
</style>
