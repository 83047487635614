<template lang="pug">
include ../pug/svg
div.field
	select(v-model="modelColor" @change="fieldUpdated")
		option(:value="null") Default
		option(:value="customColour") Custom
		option(v-for="colour in cssPallet" :value="colour.hex") {{colour.name}}
	input.hex(type="text" maxlength="6" placeholder="None" v-model="modelColor" @keyup="fieldUpdated")
	button.colour(type="button" disabled :style="{'background-color' : hex}")
	label {{label}}
	+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
</template>

<script>
export default {
	name: 'FieldColour',
	props: {
		label: {
			type: String,
			default: 'Colour',
		},
		colour: String,
	},
	emits: ['fieldUpdated','update:colour'],
	computed: {
		modelColor: {
			get() {
				return this.colour;
			},
			set(value) {
				this.$emit('update:colour', value);
			}
		},
		cssPallet() {
			return this.$store.state.gui.cssPallet;
		},
		hex() {
			// check for valid hex values (e.g(#FFF / #FFFFFF)
			const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
			const valid = hexRegex.test('#'+this.colour);
			
			return valid ? '#' + this.colour : 'transparent';
		},
	},
	methods: {
		customColour(colour) {
			let custom = true;
			
			if (colour) {
				for(let key of Object.keys(this.cssPallet)) {
					if (this.cssPallet[key].hex === colour) {
						custom = false;
					}
				}
			} else {
				custom = false;
			}
			
			return (!custom) ? 'ignore' : colour;
		},
		fieldUpdated() {
			this.$emit('fieldUpdated');
		},
	},
}
</script>

<style lang="scss">
</style>
