<template lang="pug">
include ../pug/svg
div.block(:class="blockClasses" @mouseleave="focus=null" :style="blockStyles")
	SlickList(tag="ul" lockAxis="y" v-model:list="items" useDragHandle=true helperClass="sorting" useWindowAsScrollContainer=true @update:list="blockReordered")
		SlickItem(tag="li" v-for="(item, i) in items" :index="i" :key="'li'+i" class="ulid")
			contenteditable(tag="span" v-model="item.value" :noNL="true" :spellcheck="(focus===i)" :class="{fade:focus!==null&&focus!==i}" @focus="focus=i" @blur="focus=null" @keydown="onKeydown" @keyup="onKeyup")
			button.but.drag(type="button" v-handle)
				+svg(svg-filename="iconDrag" aria-hidden="true" alt="Drag icon")
				span Reorder List
	div.modal.settings(v-if="block.settings.active")
		ModalBar(label="Block Settings" :useDrag="true" @close="toggleSettings()")
		form.body
			fieldset.fgroup
				div.field.c2
					select(v-model="block.settings.alignHorz" @change="blockUpdated")
						option(:value="nullValue") Default
						option(value="txt-left") Left Aligned
						option(value="txt-center") Centered
						option(value="txt-right") Right Aligned
					label Text Alignment
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field.c2.merge
					select(v-model="block.settings.padTop" @change="blockUpdated")
						option(:value="nullValue") Default
						option(value="pt-off") None
						option(value="pt-hlf") Half
						option(value="pt-dbl") Double
					label Padding top
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
					select(v-model="block.settings.padBottom" @change="blockUpdated")
						option(:value="nullValue") Default
						option(value="pb-off") None
						option(value="pb-hlf") Half
						option(value="pb-dbl") Double
					label Padding bottom
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field.c2
					select(v-model="block.settings.bgColor" @change="blockUpdated")
						option(:value="nullValue") Default
						option(:value="customBgColour") Custom
						option(v-for="colour in cssPallet" :value="colour.hex") {{colour.name}}
					input.hex(type="text" maxlength="6" placeholder="None" v-model="block.settings.bgColor" @keyup="blockUpdated")
					button.colour(type="button" disabled :style="{'background-color' : bgColour()}")
					label Background Colour
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field.c2
					input(type="checkbox" v-model="block.settings.whiteText" value="true" @change="blockUpdated")
					label Use White Text
					span.on(v-if="block.settings.whiteText") Yes
					span.off(v-else) No
				div.field.c2
					select(v-model="block.settings.minRatio" @change="blockUpdated")
						option(:value="nullValue") Natural
						option(value="ratio-fullheight") Full Height
						option(value="ratio-square") Square
						option(value="ratio-classic") Classic
						option(value="ratio-camera") Camera
						option(value="ratio-widescreen") Widescreen
						option(value="ratio-cinema") Cinema
					label Minimum Block Ratio
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
			div.foot
				span.req Required
				button.but.pri(type="button" @click="toggleSettings()") Apply
				button.but.sec(type="button" @click="resetSettings()") Reset
	//-pre {{block.settings}}
</template>

<script>
import contenteditable from 'vue-contenteditable';
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort';
import ModalBar from '../components/ModalBar';

export default {
	name: 'BlockListBulleted',
	props:['block'],
	emits: ['updateListItems', 'toggleSettings'],
	directives: { handle: HandleDirective },
	components: {
		SlickList,
		SlickItem,
		contenteditable,
		ModalBar,
	},
	data() {
		return {
			focus: null,
			keyDownIndex: null,
			emptyTextIndex: null,
			prevTextLength: null,
			nullValue: null,
		}
	},
	computed: {
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];

			if (this.settings.alignHorz) {
				classes.push(this.settings.alignHorz);
			}

			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}

			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}

			if (this.settings.whiteText) {
				classes.push('whitetext');
			}

			return classes.join(' ');
		},
		blockStyles() {
			let styles = [];

			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}

			return styles.join(';');
		},
		items: {
			get() {
				return this.block.content.items;
			},
			set() {
			}
		},
		cssPallet() {
			return this.$store.state.gui.cssPallet;
		},
		customBgColour() {
			return this.customColour(this.block.settings.bgColor);
		},
	},
	methods: {
		customColour(colour) {
			let custom = true;

			if (colour) {
				for(let key of Object.keys(this.cssPallet)) {
					if (this.cssPallet[key].hex === colour) {
						custom = false;
					}
				}
			} else {
				custom = false;
			}

			return (!custom) ? 'ignore' : colour;
		},
		blockUpdated() {
			this.$emit('blockUpdated');
		},
		toggleSettings() {
			this.$emit('toggleSettings', this.block);
		},
		resetSettings() {
			this.$emit('resetSettings', this.block);
		},
		bgColour() {
			// check for valid hex values (e.g(#FFF / #FFFFFF)
			const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
			const valid = hexRegex.test('#'+this.block.settings.bgColor);

			return valid ? '#' + this.block.settings.bgColor : '#FFFFFF';
		},
		blockReordered(items) {
			this.$emit('updateListItems', {id: this.block.id, items: items});
		},
		onKeydown(event) {
			const range = document.getSelection().getRangeAt(0);
			const text = range.commonAncestorContainer.data || ''; // important
			const index = this.items.findIndex(i => i.value === text);
			const key = event.key.toLowerCase();

			this.keyDownIndex = index;

			if (key === 'enter') {
				// store curent caret position / selection
				const prevStr = text.substr(0, range.startOffset).trim();
				const nextStr = text.substr(range.endOffset, text.length).trim();

				if (range.startOffset === 0) { // start of string
					this.items[index].value = nextStr;
					this.items.splice(index, 0, { value: '' });

				} else { // mid string
					this.items[index].value = prevStr;
					this.items.splice((index + 1), 0, { value: nextStr });
				}

			} else if (key === 'backspace') {
				if (range.startOffset === 0 && index > 0) {
					// merge with previous item
					this.items[index-1].value += ' ' + text;
					this.items.splice(index, 1);

					// store previous item text length for caret positioning
					const prevItem = event.currentTarget.parentNode.previousElementSibling.firstElementChild;
					this.prevTextLength = prevItem.innerText.length;
					prevItem.focus();
				}
			}
		},
		onKeyup(event) {
			const range = document.getSelection().getRangeAt(0);
			const text = range.commonAncestorContainer.data;
			const key = event.key.toLowerCase();

			if (key === 'enter') {
				const nextItem = event.currentTarget.parentNode.nextElementSibling.firstElementChild;
				nextItem.focus();

			} else if (key === 'backspace') {
				if (this.emptyTextIndex === this.keyDownIndex) {
					// item flagged as empty so delete
					this.items.splice(this.emptyTextIndex, 1);

					// reset empty flag
					this.emptyTextIndex = null;

					const prevItem = event.currentTarget.parentNode.previousElementSibling.firstElementChild;

					prevItem.focus();

					// position caret at end of text node
					const range = document.getSelection().getRangeAt(0);
					range.setStart(prevItem.childNodes[0], prevItem.innerText.length);
					range.collapse(true);

				} else if (!text && this.items.length > 1) {
					// flag item as empty
					this.emptyTextIndex = this.keyDownIndex;

				} else {
					if (range.startOffset === 0 && this.keyDownIndex > 0) {
						// position caret at end of text node
						const curItem = event.currentTarget;
						curItem.focus();

						const range = document.getSelection().getRangeAt(0);
						range.setStart(curItem.childNodes[0], this.prevTextLength + 1);
						range.collapse(true);

						this.prevTextLength = null;
					}

				}
			}

			this.$emit('updateListItems', {id: this.block.id, items: this.items});
		},
	},
}
</script>

<style lang="scss">
</style>
