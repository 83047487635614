<template lang="pug">
div.field
	input(type="checkbox" v-model="value" :value="true" @change="fieldUpdated" :disabled="disabled")
	label {{label}}
	span.on(v-if="value") {{yesLabel}}
	span.off(v-else) {{noLabel}}
</template>

<script>
export default {
	name: 'FieldBoolean',
	props: {
		modelValue: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: 'Boolean',
		},
		yesLabel: {
			type: String,
			default: 'Yes',
		},
		noLabel: {
			type: String,
			default: 'No',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['fieldUpdated','update:modelValue'],
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		},
	},
	methods: {
		fieldUpdated() {
			this.$emit('fieldUpdated');
		},
	},
}
</script>

<style lang="scss">
</style>
