<template lang="pug">
include ../pug/svg
div.block(:class="blockClasses" @mouseleave="focus=null" :style="blockStyles")
	//-pre settings: {{block.settings}}
	//-pre subType: {{subType}}
	contenteditable.question(tag="h2" v-model="block.content.question" :noNL="true" :spellcheck="(focus===-1)" :class="{fade:focus!==null&&focus!==-1}" @focus="focus=-1" @blur="focus=null" @keyup="blockUpdated")
	contenteditable.subheading(tag="h3" v-model="block.content.heading" :noNL="true" :spellcheck="(focus===-2)" :class="{fade:focus!==null&&focus!==-2}" @focus="focus=-2" @blur="focus=null" @keyup="blockUpdated")
	p.points(v-if="course.examMode&&course.examShowAllocation")
		template(v-if="settings.examPoints > 1") {{terminology('course','point','singular')}} range [ 0 - {{settings.examPoints}} {{terminology('course','point','plural')}} ]
		template(v-else) {{terminology('course','point','singular')}} range [ 0 - {{settings.examPoints}} {{terminology('course','point','singular')}} ]
	SlickList(tag="ul" lockAxis="y" v-model:list="block.content.answers" useDragHandle=true helperClass="sorting" useWindowAsScrollContainer=true @update:list="blockReordered")
		SlickItem(tag="li" v-for="(answer, i) in block.content.answers" :index="i" :key="'li'+i" class="checkboxid")
			div.answer(:class="{correct:answer.correct}")
				label Correct Answer?
					+svg(svg-filename="iconClose" aria-hidden="true" alt="Incorrct icon")
					+svg(svg-filename="iconTick" aria-hidden="true" alt="Correct icon")
					input(type="checkbox" name="correct" v-model="answer.correct" @change="correctUpdated(i)")
				contenteditable(tag="span" v-model="answer.option" :noNL="true" :spellcheck="(focus===i)" :class="{fade:focus!==null&&focus!==i}" @focus="focus=i" @blur="focus=null" @keyup="blockUpdated")
				button.but.icon(v-if="block.settings.feedback==='individual'" type="button" @click="toggleFeedback(i)")
					+svg(svg-filename="iconFeedback" aria-hidden="true" alt="Feedback icon")
					span Add Feedback
				button.but.icon(v-if="block.settings.feedback==='global'" type="button" @click="toggleFeedback('correct')")
					+svg(svg-filename="iconFeedbackCorrect" aria-hidden="true" alt="Feedback icon")
					span Add Correct Feedback
				button.but.icon(v-if="block.settings.feedback==='global'" type="button" @click="toggleFeedback('incorrect')")
					+svg(svg-filename="iconFeedbackIncorrect" aria-hidden="true" alt="Feedback icon")
					span Add Incorrect Feedback
			button.but.drag(type="button" v-handle @drag="toggleFeedback(null)")
				+svg(svg-filename="iconDrag" aria-hidden="true" alt="Drag icon")
				span Reorder Answer
			button.but.bin(type="button" @click="binAnswer(i)" :disabled="disableBin")
				+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
				span Remove Answer
			button.but.add(type="button" @click="addAnswer(i+1)")
				+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
				span Add Answer
			div.modal.feedback(v-if="block.settings.feedback" :class="{active:activeFeedback===i}")
				div(v-if="block.settings.feedback==='individual'")
					span(v-if="answer.correct")
						+svg(svg-filename="iconHappy" aria-hidden="true" alt="Correct icon")
					span(v-else)
						+svg( svg-filename="iconSad" aria-hidden="true" alt="Incorrect icon")
					contenteditable(tag="h2" v-model="answer.feedback.heading" :noNL="true" @keyup="blockUpdated")
					contenteditable(tag="div" v-model="answer.feedback.body" :noNL="false" @keyup="blockUpdated")
					button.but.pri(type="button" @click="toggleFeedback(null)") Close
	div.modal.feedback(v-if="block.settings.feedback" :class="{active:activeFeedback==='correct'||activeFeedback==='incorrect'}")
		div(v-if="activeFeedback==='correct'")
			span
				+svg(svg-filename="iconHappy" aria-hidden="true" alt="Correct icon")
			contenteditable(tag="h2" v-model="block.content.feedback.correct.heading" :noNL="true" @keyup="blockUpdated")
			contenteditable(tag="div" v-model="block.content.feedback.correct.body" :noNL="false" @keyup="blockUpdated")
			button.but.pri(type="button" @click="toggleFeedback(null)") Close
		div(v-if="activeFeedback==='incorrect'")
			span
				+svg(svg-filename="iconSad" aria-hidden="true" alt="Incorrect icon")
			contenteditable(tag="h2" v-model="block.content.feedback.incorrect.heading" :noNL="true" @keyup="blockUpdated")
			contenteditable(tag="div" v-model="block.content.feedback.incorrect.body" :noNL="false" @keyup="blockUpdated")
			button.but.pri(type="button" @click="toggleFeedback(null)") Close
	div.modal.settings(v-if="block.settings.active")
		ModalBar(label="Block Settings" :useDrag="true" @close="toggleSettings()")
		//-pre {{block.settings}}
		form.body
			fieldset.fgroup
				div.field.c2
					select(v-model="block.settings.feedback" @change="blockUpdated")
						option(:value="false") None
						option(value="global") Global
						option(value="individual") Individual
					label Provide Feedback
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field.c2
					input(type="checkbox" v-model="block.settings.wide" value="true" @change="blockUpdated")
					label Fullwidth Fields
					span.on(v-if="block.settings.wide") Yes
					span.off(v-else) No
				div.field.c2
					input(type="checkbox" v-model="block.settings.randomise" value="true" @change="blockUpdated")
					label Randomise Answers
					span.on(v-if="block.settings.randomise") Yes
					span.off(v-else) No
				div.field.c2
					select(v-model="block.settings.minRatio" @change="blockUpdated")
						option(:value="nullValue") Natural
						option(value="ratio-fullheight") Full Height
						option(value="ratio-square") Square
						option(value="ratio-classic") Classic
						option(value="ratio-camera") Camera
						option(value="ratio-widescreen") Widescreen
						option(value="ratio-cinema") Cinema
					label Minimum Block Ratio
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field.c2
					select(v-model="block.settings.alignHorz" @change="blockUpdated")
						option(:value="nullValue") Default
						option(value="txt-left") Left Aligned
						option(value="txt-center") Centered
						option(value="txt-right") Right Aligned
					label Text Alignment
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				FieldText.c2(v-if="course.examMode" name="examPoints" :label="'Exam '+terminology('course','point','plural')" v-model.number="block.settings.examPoints" :required="true" :numeric="true" :maxlength="3" :append="terminologyNumeric(block.settings.examPoints,'course','point','plural')" @keyup="blockUpdated")
			div.foot
				span.req Required
				button.but.pri(type="button" @click="toggleSettings()") Apply
				button.but.sec(type="button" @click="resetSettings()") Reset
</template>

<script>
import contenteditable from 'vue-contenteditable';
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort';
import FieldText from './FieldText';
import ModalBar from '../components/ModalBar';

export default {
	name: 'BlockTaskCheckbox',
	props:['course','block'],
	emits: ['blockUpdated', 'toggleSettings', 'resetSettings', 'updateListItems'],
	directives: { handle: HandleDirective },
	components: {
		SlickList,
		SlickItem,
		contenteditable,
		ModalBar,
		FieldText,
	},
	data() {
		return {
			focus: null,
			keyDownIndex: null,
			emptyTextIndex: null,
			activeFeedback: null,
			nullValue: null, // fix
		}
	},
	computed: {
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];
			
			if (this.settings.alignHorz) {
				classes.push(this.settings.alignHorz);
			}
			
			if (this.settings.wide) {
				classes.push('wide');
			}
			
			return classes.join(' ');
		},
		blockStyles() {
			let styles = [];

			return styles.join(';');
		},
		items: {
			get() {
				return this.block.content.answers;
			},
			set() {
			}
		},
		disableBin() {
			return (this.items.length <= 1);
		},
	},
	methods: {
		terminologyNumeric(number, groupKey, termKey, lowercase) {
			const varKey = (number == 1) ? 'singular' : 'plural';
			return this.terminology(groupKey, termKey, varKey, lowercase);
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		blockUpdated() {
			this.$emit('blockUpdated');
		},
		toggleSettings() {
			this.$emit('toggleSettings', this.block);
		},
		resetSettings() {
			this.$emit('resetSettings', this.block);
		},
		blockReordered(items) {
			this.$emit('updateListItems', {id: this.block.id, items: items});
		},
		correctUpdated(index) {
			if (!this.block.rules.multiple) {
				for (let i in this.items) {
					this.items[i].correct = (parseInt(i) === parseInt(index));
				}
			}
			this.blockUpdated();
		},
		toggleFeedback(index) {
			if (this.activeFeedback === index) {
				this.activeFeedback = null;

			} else {
				this.activeFeedback = index;
			}

			this.$store.dispatch('gui/setTintState', (this.activeFeedback !== null));
		},
		addAnswer(index) {
			this.items.splice(index, 0, {
				option: null,
//				value: '',
				feedback: {
					heading: null,
					body: null,
				},
				correct: false,
			});

			this.blockUpdated();
		},
		binAnswer(index) {
			if (this.items.length > 1) {
				this.items.splice(index, 1);
				this.blockUpdated();
			}
		},
	},
}
</script>

<style lang="scss">
</style>
