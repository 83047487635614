<template lang="pug">
include ../pug/svg
div.block(:class="blockClasses" @mouseleave="focus=null" :style="blockStyles")
	//-pre {{block.content.onecolumn}}
	picture.bg(v-if="block.settings.bgImage.media" :class="bgClasses")
		img(:src="block.settings.bgImage.media.signedUrl" :alt="block.settings.bgImage.media.seoAlt")
	hgroup
		contenteditable(tag="h2" :spellcheck="(focus===1)" :class="{fade:focus&&focus!==1}" :contenteditable="editable" v-model="block.content.headed" :noNL="true" @focus="focus=1" @blur="focus=null" @keyup="blockUpdated")
		contenteditable(tag="h3" :spellcheck="(focus===2)" :class="{fade:focus&&focus!==2, first:block.settings.subheadedFirst}" :contenteditable="editable" v-model="block.content.subheaded" :noNL="true" @focus="focus=2" @blur="focus=null" @keyup="blockUpdated")
	
	contenteditable.p(tag="div" :spellcheck="(focus===3)" :class="{fade:focus&&focus!==3}" :contenteditable="editable" v-model="block.content.onecolumn" :noNL="false" @focus="focus=3" @blur="focus=null" @keyup="blockUpdated" :noHTML="false")
	
	div.modal.settings(v-if="block.settings.active")
		ModalBar(label="Block Settings" :useDrag="true" @close="toggleSettings()")
		form.body
			fieldset.fgroup
				FieldBoolean.c2(
					v-model="block.settings.subheadedFirst"
					label="Display Subheading First"
					@fieldUpdated="blockUpdated")
				FieldAlign(
					class="c2"
					v-model:alignHorz="block.settings.alignHorz"
					@fieldUpdated="blockUpdated")
				FieldPadding(
					class="c2"
					v-model:padTop="block.settings.padTop"
					v-model:padBottom="block.settings.padBottom" 
					@fieldUpdated="blockUpdated")
				FieldBackgroundColour(
					class="c2"
					v-model:bgColor="block.settings.bgColor"
					v-model:whiteText="block.settings.whiteText" 
					@fieldUpdated="blockUpdated")
				FieldRatio.c2(
					v-model:ratio="block.settings.minRatio"
					label="Minimum Block Ratio"
					@fieldUpdated="blockUpdated")
				//-div.field.grow
					div.butdbl.text(:class="{unselected : !block.settings.bgImage.media && !block.settings.bgImage.embed}")
						button.but(type="button" @click="selectBgMedia()") Media
						button.but.bin(type="button" @click="removeBgMedia()")
							span Remove Media
							+svg(svg-filename="iconBin")
						button.but(type="button" @click="embedBgMedia()") Embed
					label Background Image
					span.on(v-if="block.settings.bgImage.media") {{block.settings.bgImage.media.filename}}
					span.off(v-else) None
			div.foot
				span.req Required
				button.but.pri(type="button" @click="toggleSettings()") Apply
				button.but.sec(type="button" @click="resetSettings()") Reset
</template>

<script>
import contenteditable from 'vue-contenteditable';
import FieldBackgroundColour from '../components/FieldBackgroundColour';
import FieldBoolean from '../components/FieldBoolean';
import FieldPadding from '../components/FieldPadding';
import FieldAlign from '../components/FieldAlign';
import FieldRatio from '../components/FieldRatio';
import ModalBar from '../components/ModalBar';

export default {
	name: 'BlockTextHeaderGroup',
	props:['block'],
	emits: ['blockUpdated', 'toggleSettings', 'resetSettings', 'showMediaModal', 'showEmbedModal', 'removeMedia'],
	components: {
		contenteditable,
		ModalBar,
		FieldBackgroundColour,
		FieldBoolean,
		FieldPadding,
		FieldAlign,
		FieldRatio,
	},
	data() {
		return {
			focus: null,
			editable: true,
			mediaType: 'image',
		}
	},
	computed: {
		settings() {
			return this.block.settings;
		},
		blockStyles() {
			let styles = [];
			
			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}
			
			return styles.join(';');
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];
			
			if (this.settings.alignHorz) {
				classes.push(this.settings.alignHorz);
			}
			
			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}
			
			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}
			
			if (this.settings.whiteText) {
				classes.push('whitetext');
			}
			
			return classes.join(' ');
		},
		bgClasses() {
			let classes = [
				'posX' + this.block.settings.bgImage.positionX,
				'posY' + this.block.settings.bgImage.positionY,
			];
			return classes.join(' ');
		},
	},
	methods: {
		blockUpdated() {
			this.$emit('blockUpdated');
		},
		toggleSettings() {
			if (this.$store.state.gui.showMediaModal) {
				// prevent settings closure until selection
				return;
			}

			this.$emit('toggleSettings', this.block);
		},
		resetSettings() {
			this.$emit('resetSettings', this.block);
		},
		selectBgMedia() {
			// pass media type and block for insertion
			this.$emit('showMediaModal', {
				mediaType: this.mediaType,
				block: this.block,
				insertIndex: 'background',
			});
		},
		embedBgMedia() {
		},
		removeBgMedia() {
			this.$emit('removeMedia', {
				block: this.block,
				index: 'background',
			});
		},
	},
}
</script>

<style lang="scss">
</style>
